@charset "UTF-8";
@font-face {
  font-family: 'Open Sans';
  src: url("../font/open-sans-v34-latin-700.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Open Sans';
  src: url("../font/open-sans-v34-latin-regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Open Sans';
  src: url("../font/open-sans-v34-latin-300.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap; }

/**
 * Foundation for Sites by ZURB
 * Version 6.3.1
 * foundation.zurb.com
 * Licensed under MIT Open Source
 */
/**
 * Foundation for Sites by ZURB
 * Version 6.3.1
 * foundation.zurb.com
 * Licensed under MIT Open Source
 */
ul.primary {
  border-bottom: none;
  text-align: left;
  white-space: normal;
  padding: 0; }
  ul.primary li {
    display: inline-block;
    background: #4A494A;
    margin: 5px;
    padding: 7px 15px;
    transition: all 0.6s; }
    ul.primary li:hover {
      background-color: #cc4b37; }
    ul.primary li a {
      border: none;
      color: #FAFAFA;
      background: none;
      transition: all 0.6s; }
      ul.primary li a:hover {
        background-color: transparent; }
    ul.primary li.active {
      background: #BCE048; }
      ul.primary li.active:hover {
        background-color: #4A494A; }
      ul.primary li.active a {
        border: none;
        background: #4A494A;
        background: none; }
        ul.primary li.active a:hover {
          background-color: transparent; }

.profile, #user-login {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
  padding-right: 1.25rem;
  padding-left: 1.25rem; }

.media-bulk-upload-multiedit-form {
  padding: 30px; }

div.status {
  background-color: #e1e1e1;
  border: none; }

.view-view-personen-mit-bild .inline {
  display: flex;
  margin: 0 auto 120px auto;
  flex-flow: row wrap;
  width: 92%; }
  @media screen and (max-width: 64em) {
    .view-view-personen-mit-bild .inline {
      margin: 0 auto 120px auto; } }
  .view-view-personen-mit-bild .inline :nth-child(1) {
    order: 1; }
  .view-view-personen-mit-bild .inline :nth-child(2) {
    order: 1; }
  .view-view-personen-mit-bild .inline :nth-child(3) {
    order: 2; }
  .view-view-personen-mit-bild .inline :nth-child(4) {
    order: 2; }
  .view-view-personen-mit-bild .inline .views-row {
    justify-content: center;
    width: 285px;
    display: inline-block;
    margin: 0 auto;
    padding: 10px 0;
    flex-basis: 25%; }
    .view-view-personen-mit-bild .inline .views-row h2 {
      display: none; }
    .view-view-personen-mit-bild .inline .views-row .node-person {
      padding: 0 35px; }
      @media screen and (max-width: 64em) {
        .view-view-personen-mit-bild .inline .views-row .node-person {
          padding: 20px 40px; } }
      .view-view-personen-mit-bild .inline .views-row .node-person .field-name-field-bild {
        max-width: 210px;
        position: relative; }
        .view-view-personen-mit-bild .inline .views-row .node-person .field-name-field-bild:before {
          content: "";
          display: inline-block;
          width: 120px;
          height: 3px;
          position: relative;
          top: 7px;
          background-color: #BCE048;
          color: #FAFAFA; }
        .view-view-personen-mit-bild .inline .views-row .node-person .field-name-field-bild:after {
          content: "";
          width: 0;
          height: 0;
          border-bottom: 50px solid #fafafa;
          border-left: 50px solid transparent;
          position: absolute;
          right: 0;
          bottom: 0;
          background-color: transparent; }
      .view-view-personen-mit-bild .inline .views-row .node-person .field-name-field-vorname {
        display: inline-block;
        font-weight: 200;
        font-size: 22px;
        padding: 2px 0; }
      .view-view-personen-mit-bild .inline .views-row .node-person .field-name-field-nachname {
        font-weight: bold;
        display: inline-block;
        margin-left: 5px;
        font-size: 22px;
        padding: 5px 0; }
      .view-view-personen-mit-bild .inline .views-row .node-person .field-name-field-beruf {
        font-size: 18px;
        font-weight: lighter;
        padding: 5px 0; }
        .view-view-personen-mit-bild .inline .views-row .node-person .field-name-field-beruf a {
          color: #212522;
          pointer-events: none !important; }
      .view-view-personen-mit-bild .inline .views-row .node-person .field-name-field-email {
        display: inline-block;
        position: relative;
        width: 300px;
        padding: 5px 0 0 35px; }
        .view-view-personen-mit-bild .inline .views-row .node-person .field-name-field-email .field-items {
          display: inherit; }
          .view-view-personen-mit-bild .inline .views-row .node-person .field-name-field-email .field-items .field-item a {
            color: #212522;
            margin-left: 6px; }
        .view-view-personen-mit-bild .inline .views-row .node-person .field-name-field-email:before {
          display: inline-block;
          position: absolute;
          top: calc(50% + 3px);
          transform: translateY(-50%);
          left: 0;
          content: "  ";
          width: 30px;
          height: 20px;
          background-size: cover;
          background-image: url("/sites/all/themes/gensel/img/icon-email.svg"); }
      .view-view-personen-mit-bild .inline .views-row .node-person .field-name-field-telefon {
        display: inline-block;
        width: 300px;
        position: relative;
        padding: 5px 0 0 30px; }
        .view-view-personen-mit-bild .inline .views-row .node-person .field-name-field-telefon .field-items {
          display: inherit; }
          .view-view-personen-mit-bild .inline .views-row .node-person .field-name-field-telefon .field-items .field-item {
            color: #212522;
            margin-left: 10px; }
        .view-view-personen-mit-bild .inline .views-row .node-person .field-name-field-telefon:before {
          display: inline-block;
          content: "  ";
          position: absolute;
          top: calc(50% + 5px);
          transform: translateY(-50%);
          left: 0;
          width: 25px;
          height: 25px;
          background-size: cover;
          background-image: url("/sites/all/themes/gensel/img/icon-telefon.svg"); }

/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
/* Document
       ========================================================================== */
/**
     * 1. Change the default font family in all browsers (opinionated).
     * 2. Correct the line height in all browsers.
     * 3. Prevent adjustments of font size after orientation changes in
     *    IE on Windows Phone and in iOS.
     */
html {
  font-family: sans-serif;
  /* 1 */
  line-height: 1.15;
  /* 2 */
  -ms-text-size-adjust: 100%;
  /* 3 */
  -webkit-text-size-adjust: 100%;
  /* 3 */ }

/* Sections
       ========================================================================== */
/**
     * Remove the margin in all browsers (opinionated).
     */
body {
  margin: 0; }

/**
     * Add the correct display in IE 9-.
     */
article,
aside,
footer,
header,
nav,
section {
  display: block; }

/**
     * Correct the font size and margin on `h1` elements within `section` and
     * `article` contexts in Chrome, Firefox, and Safari.
     */
h1 {
  font-size: 2em;
  margin: 0.67em 0; }

/* Grouping content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
figcaption,
figure {
  display: block; }

/**
     * Add the correct margin in IE 8.
     */
figure {
  margin: 1em 40px; }

/**
     * 1. Add the correct box sizing in Firefox.
     * 2. Show the overflow in Edge and IE.
     */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */ }

/**
     * Add the correct display in IE.
     */
main {
  display: block; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/* Links
       ========================================================================== */
/**
     * 1. Remove the gray background on active links in IE 10.
     * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
     */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */ }

/**
     * Remove the outline on focused links when they are also active or hovered
     * in all browsers (opinionated).
     */
a:active,
a:hover {
  outline-width: 0; }

/* Text-level semantics
       ========================================================================== */
/**
     * 1. Remove the bottom border in Firefox 39-.
     * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
     */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */ }

/**
     * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
     */
b,
strong {
  font-weight: inherit; }

/**
     * Add the correct font weight in Chrome, Edge, and Safari.
     */
b,
strong {
  font-weight: bolder; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/**
     * Add the correct font style in Android 4.3-.
     */
dfn {
  font-style: italic; }

/**
     * Add the correct background and color in IE 9-.
     */
mark {
  background-color: #ff0;
  color: #000; }

/**
     * Add the correct font size in all browsers.
     */
small {
  font-size: 80%; }

/**
     * Prevent `sub` and `sup` elements from affecting the line height in
     * all browsers.
     */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

/* Embedded content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
audio,
video {
  display: inline-block; }

/**
     * Add the correct display in iOS 4-7.
     */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
     * Remove the border on images inside links in IE 10-.
     */
img {
  border-style: none; }

/**
     * Hide the overflow in IE.
     */
svg:not(:root) {
  overflow: hidden; }

/* Forms
       ========================================================================== */
/**
     * 1. Change the font styles in all browsers (opinionated).
     * 2. Remove the margin in Firefox and Safari.
     */
button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */ }

/**
     * Show the overflow in IE.
     */
button {
  overflow: visible; }

/**
     * Remove the inheritance of text transform in Edge, Firefox, and IE.
     * 1. Remove the inheritance of text transform in Firefox.
     */
button,
select {
  /* 1 */
  text-transform: none; }

/**
     * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
     *    controls in Android 4.
     * 2. Correct the inability to style clickable types in iOS and Safari.
     */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */ }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  /**
       * Remove the inner border and padding in Firefox.
       */
  /**
       * Restore the focus styles unset by the previous rule.
       */ }
  button::-moz-focus-inner,
  [type="button"]::-moz-focus-inner,
  [type="reset"]::-moz-focus-inner,
  [type="submit"]::-moz-focus-inner {
    border-style: none;
    padding: 0; }
  button:-moz-focusring,
  [type="button"]:-moz-focusring,
  [type="reset"]:-moz-focusring,
  [type="submit"]:-moz-focusring {
    outline: 1px dotted ButtonText; }

/**
     * Show the overflow in Edge.
     */
input {
  overflow: visible; }

/**
     * 1. Add the correct box sizing in IE 10-.
     * 2. Remove the padding in IE 10-.
     */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
     * Correct the cursor style of increment and decrement buttons in Chrome.
     */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

/**
     * 1. Correct the odd appearance in Chrome and Safari.
     * 2. Correct the outline style in Safari.
     */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
  /**
       * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
       */ }
  [type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none; }

/**
     * 1. Correct the inability to style clickable types in iOS and Safari.
     * 2. Change font properties to `inherit` in Safari.
     */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */ }

/**
     * Change the border, margin, and padding in all browsers (opinionated).
     */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

/**
     * 1. Correct the text wrapping in Edge and IE.
     * 2. Correct the color inheritance from `fieldset` elements in IE.
     * 3. Remove the padding so developers are not caught out when they zero out
     *    `fieldset` elements in all browsers.
     */
legend {
  box-sizing: border-box;
  /* 1 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  color: inherit;
  /* 2 */
  white-space: normal;
  /* 1 */ }

/**
     * 1. Add the correct display in IE 9-.
     * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
     */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */ }

/**
     * Remove the default vertical scrollbar in IE.
     */
textarea {
  overflow: auto; }

/* Interactive
       ========================================================================== */
/*
     * Add the correct display in Edge, IE, and Firefox.
     */
details {
  display: block; }

/*
     * Add the correct display in all browsers.
     */
summary {
  display: list-item; }

/*
     * Add the correct display in IE 9-.
     */
menu {
  display: block; }

/* Scripting
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
canvas {
  display: inline-block; }

/**
     * Add the correct display in IE.
     */
template {
  display: none; }

/* Hidden
       ========================================================================== */
/**
     * Add the correct display in IE 10-.
     */
[hidden] {
  display: none; }

.foundation-mq {
  font-family: "small=0em&medium=40em&large=64.0625em&xlarge=75em&xxlarge=90em"; }

html {
  box-sizing: border-box;
  font-size: 100%; }

*,
*::before,
*::after {
  box-sizing: inherit; }

body {
  margin: 0;
  padding: 0;
  background: white;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
  font-weight: normal;
  line-height: 1.5;
  color: black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

img {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  height: auto;
  -ms-interpolation-mode: bicubic; }

textarea {
  height: auto;
  min-height: 50px;
  border-radius: 0; }

select {
  box-sizing: border-box;
  width: 100%;
  border-radius: 0; }

.map_canvas img,
.map_canvas embed,
.map_canvas object,
.mqa-display img,
.mqa-display embed,
.mqa-display object {
  max-width: none !important; }

button {
  padding: 0;
  appearance: none;
  border: 0;
  border-radius: 0;
  background: transparent;
  line-height: 1; }
  [data-whatinput='mouse'] button {
    outline: 0; }

pre {
  overflow: auto; }

.is-visible {
  display: block !important; }

.is-hidden {
  display: none !important; }

.row {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto; }
  .row::before, .row::after {
    display: table;
    content: ' '; }
  .row::after {
    clear: both; }
  .row.collapse > .column, .row.collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .row .row {
    margin-right: -1.25rem;
    margin-left: -1.25rem; }
    @media print, screen and (min-width: 40em) {
      .row .row {
        margin-right: -1.25rem;
        margin-left: -1.25rem; } }
    @media print, screen and (min-width: 64.0625em) {
      .row .row {
        margin-right: -1.25rem;
        margin-left: -1.25rem; } }
    .row .row.collapse {
      margin-right: 0;
      margin-left: 0; }
  .row.expanded {
    max-width: none; }
    .row.expanded .row {
      margin-right: auto;
      margin-left: auto; }
  .row:not(.expanded) .row {
    max-width: none; }
  .row.gutter-small > .column, .row.gutter-small > .columns {
    padding-right: 1.25rem;
    padding-left: 1.25rem; }
  .row.gutter-medium > .column, .row.gutter-medium > .columns {
    padding-right: 1.25rem;
    padding-left: 1.25rem; }

.column, .columns {
  width: 100%;
  float: left;
  padding-right: 1.25rem;
  padding-left: 1.25rem; }
  @media print, screen and (min-width: 40em) {
    .column, .columns {
      padding-right: 1.25rem;
      padding-left: 1.25rem; } }
  .column:last-child:not(:first-child), .columns:last-child:not(:first-child) {
    float: right; }
  .column.end:last-child:last-child, .end.columns:last-child:last-child {
    float: left; }

.column.row.row, .row.row.columns {
  float: none; }

.row .column.row.row, .row .row.row.columns {
  margin-right: 0;
  margin-left: 0;
  padding-right: 0;
  padding-left: 0; }

.small-1 {
  width: 8.33333%; }

.small-push-1 {
  position: relative;
  left: 8.33333%; }

.small-pull-1 {
  position: relative;
  left: -8.33333%; }

.small-offset-0 {
  margin-left: 0%; }

.small-2 {
  width: 16.66667%; }

.small-push-2 {
  position: relative;
  left: 16.66667%; }

.small-pull-2 {
  position: relative;
  left: -16.66667%; }

.small-offset-1 {
  margin-left: 8.33333%; }

.small-3 {
  width: 25%; }

.small-push-3 {
  position: relative;
  left: 25%; }

.small-pull-3 {
  position: relative;
  left: -25%; }

.small-offset-2 {
  margin-left: 16.66667%; }

.small-4 {
  width: 33.33333%; }

.small-push-4 {
  position: relative;
  left: 33.33333%; }

.small-pull-4 {
  position: relative;
  left: -33.33333%; }

.small-offset-3 {
  margin-left: 25%; }

.small-5 {
  width: 41.66667%; }

.small-push-5 {
  position: relative;
  left: 41.66667%; }

.small-pull-5 {
  position: relative;
  left: -41.66667%; }

.small-offset-4 {
  margin-left: 33.33333%; }

.small-6 {
  width: 50%; }

.small-push-6 {
  position: relative;
  left: 50%; }

.small-pull-6 {
  position: relative;
  left: -50%; }

.small-offset-5 {
  margin-left: 41.66667%; }

.small-7 {
  width: 58.33333%; }

.small-push-7 {
  position: relative;
  left: 58.33333%; }

.small-pull-7 {
  position: relative;
  left: -58.33333%; }

.small-offset-6 {
  margin-left: 50%; }

.small-8 {
  width: 66.66667%; }

.small-push-8 {
  position: relative;
  left: 66.66667%; }

.small-pull-8 {
  position: relative;
  left: -66.66667%; }

.small-offset-7 {
  margin-left: 58.33333%; }

.small-9 {
  width: 75%; }

.small-push-9 {
  position: relative;
  left: 75%; }

.small-pull-9 {
  position: relative;
  left: -75%; }

.small-offset-8 {
  margin-left: 66.66667%; }

.small-10 {
  width: 83.33333%; }

.small-push-10 {
  position: relative;
  left: 83.33333%; }

.small-pull-10 {
  position: relative;
  left: -83.33333%; }

.small-offset-9 {
  margin-left: 75%; }

.small-11 {
  width: 91.66667%; }

.small-push-11 {
  position: relative;
  left: 91.66667%; }

.small-pull-11 {
  position: relative;
  left: -91.66667%; }

.small-offset-10 {
  margin-left: 83.33333%; }

.small-12 {
  width: 100%; }

.small-offset-11 {
  margin-left: 91.66667%; }

.small-up-1 > .column, .small-up-1 > .columns {
  float: left;
  width: 100%; }
  .small-up-1 > .column:nth-of-type(1n), .small-up-1 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-1 > .column:nth-of-type(1n+1), .small-up-1 > .columns:nth-of-type(1n+1) {
    clear: both; }
  .small-up-1 > .column:last-child, .small-up-1 > .columns:last-child {
    float: left; }

.small-up-2 > .column, .small-up-2 > .columns {
  float: left;
  width: 50%; }
  .small-up-2 > .column:nth-of-type(1n), .small-up-2 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-2 > .column:nth-of-type(2n+1), .small-up-2 > .columns:nth-of-type(2n+1) {
    clear: both; }
  .small-up-2 > .column:last-child, .small-up-2 > .columns:last-child {
    float: left; }

.small-up-3 > .column, .small-up-3 > .columns {
  float: left;
  width: 33.33333%; }
  .small-up-3 > .column:nth-of-type(1n), .small-up-3 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-3 > .column:nth-of-type(3n+1), .small-up-3 > .columns:nth-of-type(3n+1) {
    clear: both; }
  .small-up-3 > .column:last-child, .small-up-3 > .columns:last-child {
    float: left; }

.small-up-4 > .column, .small-up-4 > .columns {
  float: left;
  width: 25%; }
  .small-up-4 > .column:nth-of-type(1n), .small-up-4 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-4 > .column:nth-of-type(4n+1), .small-up-4 > .columns:nth-of-type(4n+1) {
    clear: both; }
  .small-up-4 > .column:last-child, .small-up-4 > .columns:last-child {
    float: left; }

.small-up-5 > .column, .small-up-5 > .columns {
  float: left;
  width: 20%; }
  .small-up-5 > .column:nth-of-type(1n), .small-up-5 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-5 > .column:nth-of-type(5n+1), .small-up-5 > .columns:nth-of-type(5n+1) {
    clear: both; }
  .small-up-5 > .column:last-child, .small-up-5 > .columns:last-child {
    float: left; }

.small-up-6 > .column, .small-up-6 > .columns {
  float: left;
  width: 16.66667%; }
  .small-up-6 > .column:nth-of-type(1n), .small-up-6 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-6 > .column:nth-of-type(6n+1), .small-up-6 > .columns:nth-of-type(6n+1) {
    clear: both; }
  .small-up-6 > .column:last-child, .small-up-6 > .columns:last-child {
    float: left; }

.small-up-7 > .column, .small-up-7 > .columns {
  float: left;
  width: 14.28571%; }
  .small-up-7 > .column:nth-of-type(1n), .small-up-7 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-7 > .column:nth-of-type(7n+1), .small-up-7 > .columns:nth-of-type(7n+1) {
    clear: both; }
  .small-up-7 > .column:last-child, .small-up-7 > .columns:last-child {
    float: left; }

.small-up-8 > .column, .small-up-8 > .columns {
  float: left;
  width: 12.5%; }
  .small-up-8 > .column:nth-of-type(1n), .small-up-8 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-8 > .column:nth-of-type(8n+1), .small-up-8 > .columns:nth-of-type(8n+1) {
    clear: both; }
  .small-up-8 > .column:last-child, .small-up-8 > .columns:last-child {
    float: left; }

.small-collapse > .column, .small-collapse > .columns {
  padding-right: 0;
  padding-left: 0; }

.small-collapse .row {
  margin-right: 0;
  margin-left: 0; }

.expanded.row .small-collapse.row {
  margin-right: 0;
  margin-left: 0; }

.small-uncollapse > .column, .small-uncollapse > .columns {
  padding-right: 1.25rem;
  padding-left: 1.25rem; }

.small-centered {
  margin-right: auto;
  margin-left: auto; }
  .small-centered, .small-centered:last-child:not(:first-child) {
    float: none;
    clear: both; }

.small-uncentered,
.small-push-0,
.small-pull-0 {
  position: static;
  float: left;
  margin-right: 0;
  margin-left: 0; }

@media print, screen and (min-width: 40em) {
  .medium-1 {
    width: 8.33333%; }
  .medium-push-1 {
    position: relative;
    left: 8.33333%; }
  .medium-pull-1 {
    position: relative;
    left: -8.33333%; }
  .medium-offset-0 {
    margin-left: 0%; }
  .medium-2 {
    width: 16.66667%; }
  .medium-push-2 {
    position: relative;
    left: 16.66667%; }
  .medium-pull-2 {
    position: relative;
    left: -16.66667%; }
  .medium-offset-1 {
    margin-left: 8.33333%; }
  .medium-3 {
    width: 25%; }
  .medium-push-3 {
    position: relative;
    left: 25%; }
  .medium-pull-3 {
    position: relative;
    left: -25%; }
  .medium-offset-2 {
    margin-left: 16.66667%; }
  .medium-4 {
    width: 33.33333%; }
  .medium-push-4 {
    position: relative;
    left: 33.33333%; }
  .medium-pull-4 {
    position: relative;
    left: -33.33333%; }
  .medium-offset-3 {
    margin-left: 25%; }
  .medium-5 {
    width: 41.66667%; }
  .medium-push-5 {
    position: relative;
    left: 41.66667%; }
  .medium-pull-5 {
    position: relative;
    left: -41.66667%; }
  .medium-offset-4 {
    margin-left: 33.33333%; }
  .medium-6 {
    width: 50%; }
  .medium-push-6 {
    position: relative;
    left: 50%; }
  .medium-pull-6 {
    position: relative;
    left: -50%; }
  .medium-offset-5 {
    margin-left: 41.66667%; }
  .medium-7 {
    width: 58.33333%; }
  .medium-push-7 {
    position: relative;
    left: 58.33333%; }
  .medium-pull-7 {
    position: relative;
    left: -58.33333%; }
  .medium-offset-6 {
    margin-left: 50%; }
  .medium-8 {
    width: 66.66667%; }
  .medium-push-8 {
    position: relative;
    left: 66.66667%; }
  .medium-pull-8 {
    position: relative;
    left: -66.66667%; }
  .medium-offset-7 {
    margin-left: 58.33333%; }
  .medium-9 {
    width: 75%; }
  .medium-push-9 {
    position: relative;
    left: 75%; }
  .medium-pull-9 {
    position: relative;
    left: -75%; }
  .medium-offset-8 {
    margin-left: 66.66667%; }
  .medium-10 {
    width: 83.33333%; }
  .medium-push-10 {
    position: relative;
    left: 83.33333%; }
  .medium-pull-10 {
    position: relative;
    left: -83.33333%; }
  .medium-offset-9 {
    margin-left: 75%; }
  .medium-11 {
    width: 91.66667%; }
  .medium-push-11 {
    position: relative;
    left: 91.66667%; }
  .medium-pull-11 {
    position: relative;
    left: -91.66667%; }
  .medium-offset-10 {
    margin-left: 83.33333%; }
  .medium-12 {
    width: 100%; }
  .medium-offset-11 {
    margin-left: 91.66667%; }
  .medium-up-1 > .column, .medium-up-1 > .columns {
    float: left;
    width: 100%; }
    .medium-up-1 > .column:nth-of-type(1n), .medium-up-1 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-1 > .column:nth-of-type(1n+1), .medium-up-1 > .columns:nth-of-type(1n+1) {
      clear: both; }
    .medium-up-1 > .column:last-child, .medium-up-1 > .columns:last-child {
      float: left; }
  .medium-up-2 > .column, .medium-up-2 > .columns {
    float: left;
    width: 50%; }
    .medium-up-2 > .column:nth-of-type(1n), .medium-up-2 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-2 > .column:nth-of-type(2n+1), .medium-up-2 > .columns:nth-of-type(2n+1) {
      clear: both; }
    .medium-up-2 > .column:last-child, .medium-up-2 > .columns:last-child {
      float: left; }
  .medium-up-3 > .column, .medium-up-3 > .columns {
    float: left;
    width: 33.33333%; }
    .medium-up-3 > .column:nth-of-type(1n), .medium-up-3 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-3 > .column:nth-of-type(3n+1), .medium-up-3 > .columns:nth-of-type(3n+1) {
      clear: both; }
    .medium-up-3 > .column:last-child, .medium-up-3 > .columns:last-child {
      float: left; }
  .medium-up-4 > .column, .medium-up-4 > .columns {
    float: left;
    width: 25%; }
    .medium-up-4 > .column:nth-of-type(1n), .medium-up-4 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-4 > .column:nth-of-type(4n+1), .medium-up-4 > .columns:nth-of-type(4n+1) {
      clear: both; }
    .medium-up-4 > .column:last-child, .medium-up-4 > .columns:last-child {
      float: left; }
  .medium-up-5 > .column, .medium-up-5 > .columns {
    float: left;
    width: 20%; }
    .medium-up-5 > .column:nth-of-type(1n), .medium-up-5 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-5 > .column:nth-of-type(5n+1), .medium-up-5 > .columns:nth-of-type(5n+1) {
      clear: both; }
    .medium-up-5 > .column:last-child, .medium-up-5 > .columns:last-child {
      float: left; }
  .medium-up-6 > .column, .medium-up-6 > .columns {
    float: left;
    width: 16.66667%; }
    .medium-up-6 > .column:nth-of-type(1n), .medium-up-6 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-6 > .column:nth-of-type(6n+1), .medium-up-6 > .columns:nth-of-type(6n+1) {
      clear: both; }
    .medium-up-6 > .column:last-child, .medium-up-6 > .columns:last-child {
      float: left; }
  .medium-up-7 > .column, .medium-up-7 > .columns {
    float: left;
    width: 14.28571%; }
    .medium-up-7 > .column:nth-of-type(1n), .medium-up-7 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-7 > .column:nth-of-type(7n+1), .medium-up-7 > .columns:nth-of-type(7n+1) {
      clear: both; }
    .medium-up-7 > .column:last-child, .medium-up-7 > .columns:last-child {
      float: left; }
  .medium-up-8 > .column, .medium-up-8 > .columns {
    float: left;
    width: 12.5%; }
    .medium-up-8 > .column:nth-of-type(1n), .medium-up-8 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-8 > .column:nth-of-type(8n+1), .medium-up-8 > .columns:nth-of-type(8n+1) {
      clear: both; }
    .medium-up-8 > .column:last-child, .medium-up-8 > .columns:last-child {
      float: left; }
  .medium-collapse > .column, .medium-collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .medium-collapse .row {
    margin-right: 0;
    margin-left: 0; }
  .expanded.row .medium-collapse.row {
    margin-right: 0;
    margin-left: 0; }
  .medium-uncollapse > .column, .medium-uncollapse > .columns {
    padding-right: 1.25rem;
    padding-left: 1.25rem; }
  .medium-centered {
    margin-right: auto;
    margin-left: auto; }
    .medium-centered, .medium-centered:last-child:not(:first-child) {
      float: none;
      clear: both; }
  .medium-uncentered,
  .medium-push-0,
  .medium-pull-0 {
    position: static;
    float: left;
    margin-right: 0;
    margin-left: 0; } }

@media print, screen and (min-width: 64.0625em) {
  .large-1 {
    width: 8.33333%; }
  .large-push-1 {
    position: relative;
    left: 8.33333%; }
  .large-pull-1 {
    position: relative;
    left: -8.33333%; }
  .large-offset-0 {
    margin-left: 0%; }
  .large-2 {
    width: 16.66667%; }
  .large-push-2 {
    position: relative;
    left: 16.66667%; }
  .large-pull-2 {
    position: relative;
    left: -16.66667%; }
  .large-offset-1 {
    margin-left: 8.33333%; }
  .large-3 {
    width: 25%; }
  .large-push-3 {
    position: relative;
    left: 25%; }
  .large-pull-3 {
    position: relative;
    left: -25%; }
  .large-offset-2 {
    margin-left: 16.66667%; }
  .large-4 {
    width: 33.33333%; }
  .large-push-4 {
    position: relative;
    left: 33.33333%; }
  .large-pull-4 {
    position: relative;
    left: -33.33333%; }
  .large-offset-3 {
    margin-left: 25%; }
  .large-5 {
    width: 41.66667%; }
  .large-push-5 {
    position: relative;
    left: 41.66667%; }
  .large-pull-5 {
    position: relative;
    left: -41.66667%; }
  .large-offset-4 {
    margin-left: 33.33333%; }
  .large-6 {
    width: 50%; }
  .large-push-6 {
    position: relative;
    left: 50%; }
  .large-pull-6 {
    position: relative;
    left: -50%; }
  .large-offset-5 {
    margin-left: 41.66667%; }
  .large-7 {
    width: 58.33333%; }
  .large-push-7 {
    position: relative;
    left: 58.33333%; }
  .large-pull-7 {
    position: relative;
    left: -58.33333%; }
  .large-offset-6 {
    margin-left: 50%; }
  .large-8 {
    width: 66.66667%; }
  .large-push-8 {
    position: relative;
    left: 66.66667%; }
  .large-pull-8 {
    position: relative;
    left: -66.66667%; }
  .large-offset-7 {
    margin-left: 58.33333%; }
  .large-9 {
    width: 75%; }
  .large-push-9 {
    position: relative;
    left: 75%; }
  .large-pull-9 {
    position: relative;
    left: -75%; }
  .large-offset-8 {
    margin-left: 66.66667%; }
  .large-10 {
    width: 83.33333%; }
  .large-push-10 {
    position: relative;
    left: 83.33333%; }
  .large-pull-10 {
    position: relative;
    left: -83.33333%; }
  .large-offset-9 {
    margin-left: 75%; }
  .large-11 {
    width: 91.66667%; }
  .large-push-11 {
    position: relative;
    left: 91.66667%; }
  .large-pull-11 {
    position: relative;
    left: -91.66667%; }
  .large-offset-10 {
    margin-left: 83.33333%; }
  .large-12 {
    width: 100%; }
  .large-offset-11 {
    margin-left: 91.66667%; }
  .large-up-1 > .column, .large-up-1 > .columns {
    float: left;
    width: 100%; }
    .large-up-1 > .column:nth-of-type(1n), .large-up-1 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-1 > .column:nth-of-type(1n+1), .large-up-1 > .columns:nth-of-type(1n+1) {
      clear: both; }
    .large-up-1 > .column:last-child, .large-up-1 > .columns:last-child {
      float: left; }
  .large-up-2 > .column, .large-up-2 > .columns {
    float: left;
    width: 50%; }
    .large-up-2 > .column:nth-of-type(1n), .large-up-2 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-2 > .column:nth-of-type(2n+1), .large-up-2 > .columns:nth-of-type(2n+1) {
      clear: both; }
    .large-up-2 > .column:last-child, .large-up-2 > .columns:last-child {
      float: left; }
  .large-up-3 > .column, .large-up-3 > .columns {
    float: left;
    width: 33.33333%; }
    .large-up-3 > .column:nth-of-type(1n), .large-up-3 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-3 > .column:nth-of-type(3n+1), .large-up-3 > .columns:nth-of-type(3n+1) {
      clear: both; }
    .large-up-3 > .column:last-child, .large-up-3 > .columns:last-child {
      float: left; }
  .large-up-4 > .column, .large-up-4 > .columns {
    float: left;
    width: 25%; }
    .large-up-4 > .column:nth-of-type(1n), .large-up-4 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-4 > .column:nth-of-type(4n+1), .large-up-4 > .columns:nth-of-type(4n+1) {
      clear: both; }
    .large-up-4 > .column:last-child, .large-up-4 > .columns:last-child {
      float: left; }
  .large-up-5 > .column, .large-up-5 > .columns {
    float: left;
    width: 20%; }
    .large-up-5 > .column:nth-of-type(1n), .large-up-5 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-5 > .column:nth-of-type(5n+1), .large-up-5 > .columns:nth-of-type(5n+1) {
      clear: both; }
    .large-up-5 > .column:last-child, .large-up-5 > .columns:last-child {
      float: left; }
  .large-up-6 > .column, .large-up-6 > .columns {
    float: left;
    width: 16.66667%; }
    .large-up-6 > .column:nth-of-type(1n), .large-up-6 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-6 > .column:nth-of-type(6n+1), .large-up-6 > .columns:nth-of-type(6n+1) {
      clear: both; }
    .large-up-6 > .column:last-child, .large-up-6 > .columns:last-child {
      float: left; }
  .large-up-7 > .column, .large-up-7 > .columns {
    float: left;
    width: 14.28571%; }
    .large-up-7 > .column:nth-of-type(1n), .large-up-7 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-7 > .column:nth-of-type(7n+1), .large-up-7 > .columns:nth-of-type(7n+1) {
      clear: both; }
    .large-up-7 > .column:last-child, .large-up-7 > .columns:last-child {
      float: left; }
  .large-up-8 > .column, .large-up-8 > .columns {
    float: left;
    width: 12.5%; }
    .large-up-8 > .column:nth-of-type(1n), .large-up-8 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-8 > .column:nth-of-type(8n+1), .large-up-8 > .columns:nth-of-type(8n+1) {
      clear: both; }
    .large-up-8 > .column:last-child, .large-up-8 > .columns:last-child {
      float: left; }
  .large-collapse > .column, .large-collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .large-collapse .row {
    margin-right: 0;
    margin-left: 0; }
  .expanded.row .large-collapse.row {
    margin-right: 0;
    margin-left: 0; }
  .large-uncollapse > .column, .large-uncollapse > .columns {
    padding-right: 1.25rem;
    padding-left: 1.25rem; }
  .large-centered {
    margin-right: auto;
    margin-left: auto; }
    .large-centered, .large-centered:last-child:not(:first-child) {
      float: none;
      clear: both; }
  .large-uncentered,
  .large-push-0,
  .large-pull-0 {
    position: static;
    float: left;
    margin-right: 0;
    margin-left: 0; } }

.column-block {
  margin-bottom: 2.5rem; }
  .column-block > :last-child {
    margin-bottom: 0; }
  @media print, screen and (min-width: 40em) {
    .column-block {
      margin-bottom: 2.5rem; }
      .column-block > :last-child {
        margin-bottom: 0; } }

.align-right {
  justify-content: flex-end; }

.align-center {
  justify-content: center; }

.align-justify {
  justify-content: space-between; }

.align-spaced {
  justify-content: space-around; }

.align-top {
  align-items: flex-start; }

.align-self-top {
  align-self: flex-start; }

.align-bottom {
  align-items: flex-end; }

.align-self-bottom {
  align-self: flex-end; }

.align-middle {
  align-items: center; }

.align-self-middle {
  align-self: center; }

.align-stretch {
  align-items: stretch; }

.align-self-stretch {
  align-self: stretch; }

.align-center-middle {
  justify-content: center;
  align-items: center;
  align-content: center; }

.small-order-1 {
  order: 1; }

.small-order-2 {
  order: 2; }

.small-order-3 {
  order: 3; }

.small-order-4 {
  order: 4; }

.small-order-5 {
  order: 5; }

.small-order-6 {
  order: 6; }

@media print, screen and (min-width: 40em) {
  .medium-order-1 {
    order: 1; }
  .medium-order-2 {
    order: 2; }
  .medium-order-3 {
    order: 3; }
  .medium-order-4 {
    order: 4; }
  .medium-order-5 {
    order: 5; }
  .medium-order-6 {
    order: 6; } }

@media print, screen and (min-width: 64.0625em) {
  .large-order-1 {
    order: 1; }
  .large-order-2 {
    order: 2; }
  .large-order-3 {
    order: 3; }
  .large-order-4 {
    order: 4; }
  .large-order-5 {
    order: 5; }
  .large-order-6 {
    order: 6; } }

div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0; }

p {
  margin-bottom: 1rem;
  font-size: inherit;
  line-height: 1.6;
  text-rendering: optimizeLegibility; }

em,
i {
  font-style: italic;
  line-height: inherit; }

strong,
b {
  font-weight: bold;
  line-height: inherit; }

small {
  font-size: 80%;
  line-height: inherit; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  color: inherit;
  text-rendering: optimizeLegibility; }
  h1 small,
  h2 small,
  h3 small,
  h4 small,
  h5 small,
  h6 small {
    line-height: 0;
    color: #e1e1e1; }

h1 {
  font-size: 1.5rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h2 {
  font-size: 1.25rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h3 {
  font-size: 1.1875rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h4 {
  font-size: 1.125rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h5 {
  font-size: 1.0625rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h6 {
  font-size: 1rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

@media print, screen and (min-width: 40em) {
  h1 {
    font-size: 3rem; }
  h2 {
    font-size: 2.5rem; }
  h3 {
    font-size: 1.9375rem; }
  h4 {
    font-size: 1.5625rem; }
  h5 {
    font-size: 1.25rem; }
  h6 {
    font-size: 1rem; } }

a {
  line-height: inherit;
  color: #4A494A;
  text-decoration: none;
  cursor: pointer; }
  a:hover, a:focus {
    color: #403f40; }
  a img {
    border: 0; }

hr {
  clear: both;
  max-width: 75rem;
  height: 0;
  margin: 1.25rem auto;
  border-top: 0;
  border-right: 0;
  border-bottom: 1px solid #e1e1e1;
  border-left: 0; }

ul,
ol,
dl {
  margin-bottom: 1rem;
  list-style-position: outside;
  line-height: 1.6; }

li {
  font-size: inherit; }

ul {
  margin-left: 1.25rem;
  list-style-type: disc; }

ol {
  margin-left: 1.25rem; }

ul ul, ol ul, ul ol, ol ol {
  margin-left: 1.25rem;
  margin-bottom: 0; }

dl {
  margin-bottom: 1rem; }
  dl dt {
    margin-bottom: 0.3rem;
    font-weight: bold; }

blockquote {
  margin: 0 0 1rem;
  padding: 0.5625rem 1.25rem 0 1.1875rem;
  border-left: 1px solid #e1e1e1; }
  blockquote, blockquote p {
    line-height: 1.6;
    color: #aaa; }

cite {
  display: block;
  font-size: 0.8125rem;
  color: #aaa; }
  cite:before {
    content: "— "; }

abbr {
  border-bottom: 1px dotted #212522;
  color: black;
  cursor: help; }

figure {
  margin: 0; }

code {
  padding: 0.125rem 0.3125rem 0.0625rem;
  border: 1px solid #e1e1e1;
  background-color: #e6e6e6;
  font-family: Consolas, "Liberation Mono", Courier, monospace;
  font-weight: normal;
  color: #212522; }

kbd {
  margin: 0;
  padding: 0.125rem 0.25rem 0;
  background-color: #e6e6e6;
  font-family: Consolas, "Liberation Mono", Courier, monospace;
  color: #212522; }

.subheader {
  margin-top: 0.2rem;
  margin-bottom: 0.5rem;
  font-weight: normal;
  line-height: 1.4;
  color: #aaa; }

.lead {
  font-size: 125%;
  line-height: 1.6; }

.stat {
  font-size: 2.5rem;
  line-height: 1; }
  p + .stat {
    margin-top: -1rem; }

.no-bullet {
  margin-left: 0;
  list-style: none; }

.text-left {
  text-align: left; }

.text-right {
  text-align: right; }

.text-center {
  text-align: center; }

.text-justify {
  text-align: justify; }

@media print, screen and (min-width: 40em) {
  .medium-text-left {
    text-align: left; }
  .medium-text-right {
    text-align: right; }
  .medium-text-center {
    text-align: center; }
  .medium-text-justify {
    text-align: justify; } }

@media print, screen and (min-width: 64.0625em) {
  .large-text-left {
    text-align: left; }
  .large-text-right {
    text-align: right; }
  .large-text-center {
    text-align: center; }
  .large-text-justify {
    text-align: justify; } }

.show-for-print {
  display: none !important; }

@media print {
  * {
    background: transparent !important;
    box-shadow: none !important;
    color: black !important;
    text-shadow: none !important; }
  .show-for-print {
    display: block !important; }
  .hide-for-print {
    display: none !important; }
  table.show-for-print {
    display: table !important; }
  thead.show-for-print {
    display: table-header-group !important; }
  tbody.show-for-print {
    display: table-row-group !important; }
  tr.show-for-print {
    display: table-row !important; }
  td.show-for-print {
    display: table-cell !important; }
  th.show-for-print {
    display: table-cell !important; }
  a,
  a:visited {
    text-decoration: underline; }
  a[href]:after {
    content: " (" attr(href) ")"; }
  .ir a:after,
  a[href^='javascript:']:after,
  a[href^='#']:after {
    content: ''; }
  abbr[title]:after {
    content: " (" attr(title) ")"; }
  pre,
  blockquote {
    border: 1px solid #aaa;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  img {
    max-width: 100% !important; }
  @page {
    margin: 0.5cm; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  .print-break-inside {
    page-break-inside: auto; } }

[type='text'], [type='password'], [type='date'], [type='datetime'], [type='datetime-local'], [type='month'], [type='week'], [type='email'], [type='number'], [type='search'], [type='tel'], [type='time'], [type='url'], [type='color'],
textarea {
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: 2.4375rem;
  margin: 0 0 1rem;
  padding: 0.5rem;
  border: 1px solid #e1e1e1;
  border-radius: 0;
  background-color: #FAFAFA;
  box-shadow: inset 0 1px 2px rgba(33, 37, 34, 0.1);
  font-family: inherit;
  font-size: 1rem;
  font-weight: normal;
  color: #212522;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
  appearance: none; }
  [type='text']:focus, [type='password']:focus, [type='date']:focus, [type='datetime']:focus, [type='datetime-local']:focus, [type='month']:focus, [type='week']:focus, [type='email']:focus, [type='number']:focus, [type='search']:focus, [type='tel']:focus, [type='time']:focus, [type='url']:focus, [type='color']:focus,
  textarea:focus {
    outline: none;
    border: 1px solid #aaa;
    background-color: #FAFAFA;
    box-shadow: 0 0 5px #e1e1e1;
    transition: box-shadow 0.5s, border-color 0.25s ease-in-out; }

textarea {
  max-width: 100%; }
  textarea[rows] {
    height: auto; }

input::placeholder,
textarea::placeholder {
  color: #e1e1e1; }

input:disabled, input[readonly],
textarea:disabled,
textarea[readonly] {
  background-color: #e6e6e6;
  cursor: not-allowed; }

[type='submit'],
[type='button'] {
  appearance: none;
  border-radius: 0; }

input[type='search'] {
  box-sizing: border-box; }

[type='file'],
[type='checkbox'],
[type='radio'] {
  margin: 0 0 1rem; }

[type='checkbox'] + label,
[type='radio'] + label {
  display: inline-block;
  vertical-align: baseline;
  margin-left: 0.5rem;
  margin-right: 1rem;
  margin-bottom: 0; }
  [type='checkbox'] + label[for],
  [type='radio'] + label[for] {
    cursor: pointer; }

label > [type='checkbox'],
label > [type='radio'] {
  margin-right: 0.5rem; }

[type='file'] {
  width: 100%; }

label {
  display: block;
  margin: 0;
  font-size: 0.875rem;
  font-weight: normal;
  line-height: 1.8;
  color: #212522; }
  label.middle {
    margin: 0 0 1rem;
    padding: 0.5625rem 0; }

.help-text {
  margin-top: -0.5rem;
  font-size: 0.8125rem;
  font-style: italic;
  color: #212522; }

.input-group {
  display: table;
  width: 100%;
  margin-bottom: 1rem; }
  .input-group > :first-child {
    border-radius: 0 0 0 0; }
  .input-group > :last-child > * {
    border-radius: 0 0 0 0; }

.input-group-label, .input-group-field, .input-group-button, .input-group-button a,
.input-group-button input,
.input-group-button button,
.input-group-button label {
  margin: 0;
  white-space: nowrap;
  display: table-cell;
  vertical-align: middle; }

.input-group-label {
  padding: 0 1rem;
  border: 1px solid #e1e1e1;
  background: #e6e6e6;
  color: #212522;
  text-align: center;
  white-space: nowrap;
  width: 1%;
  height: 100%; }
  .input-group-label:first-child {
    border-right: 0; }
  .input-group-label:last-child {
    border-left: 0; }

.input-group-field {
  border-radius: 0;
  height: 2.5rem; }

.input-group-button {
  padding-top: 0;
  padding-bottom: 0;
  text-align: center;
  width: 1%;
  height: 100%; }
  .input-group-button a,
  .input-group-button input,
  .input-group-button button,
  .input-group-button label {
    height: 2.5rem;
    padding-top: 0;
    padding-bottom: 0;
    font-size: 1rem; }

.input-group .input-group-button {
  display: table-cell; }

fieldset {
  margin: 0;
  padding: 0;
  border: 0; }

legend {
  max-width: 100%;
  margin-bottom: 0.5rem; }

.fieldset {
  margin: 1.125rem 0;
  padding: 1.25rem;
  border: 1px solid #e1e1e1; }
  .fieldset legend {
    margin: 0;
    margin-left: -0.1875rem;
    padding: 0 0.1875rem;
    background: white; }

select {
  height: 2.4375rem;
  margin: 0 0 1rem;
  padding: 0.5rem;
  appearance: none;
  border: 1px solid #e1e1e1;
  border-radius: 0;
  background-color: #FAFAFA;
  font-family: inherit;
  font-size: 1rem;
  line-height: normal;
  color: #212522;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='32' height='24' viewBox='0 0 32 24'><polygon points='0,0 32,0 16,24' style='fill: rgb%28170, 170, 170%29'></polygon></svg>");
  background-origin: content-box;
  background-position: right -1rem center;
  background-repeat: no-repeat;
  background-size: 9px 6px;
  padding-right: 1.5rem;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out; }
  @media screen and (min-width: 0\0) {
    select {
      background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAYCAYAAACbU/80AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAMBJREFUeNrEllsOhCAMRVszC9IlzU7KCmVHTJsoMWYMUtpyv9BgbuXQB5ZSdgBYYY4ycgBivk8KYFsQMfMiTTBP4o3nUzCKzOabLJbLy2/g31evGkAginR4/ZegKH5qX3bJCscA3t0x3kgO5tQFyhhFf50xRqFLbyMUNJQzgyjGS/wgCpvKqkRBpuWrE4V9d+1E4dPUXqIg107SQOE/2DRQxMwTDygIInVDET9T3lCoj/6j/VCmGjZOl2lKpZ8AAwDQP7zIimDGFQAAAABJRU5ErkJggg=="); } }
  select:focus {
    outline: none;
    border: 1px solid #aaa;
    background-color: #FAFAFA;
    box-shadow: 0 0 5px #e1e1e1;
    transition: box-shadow 0.5s, border-color 0.25s ease-in-out; }
  select:disabled {
    background-color: #e6e6e6;
    cursor: not-allowed; }
  select::-ms-expand {
    display: none; }
  select[multiple] {
    height: auto;
    background-image: none; }

.is-invalid-input:not(:focus) {
  border-color: #cc4b37;
  background-color: #f5e9e7; }
  .is-invalid-input:not(:focus)::placeholder {
    color: #cc4b37; }

.is-invalid-label {
  color: #cc4b37; }

.form-error {
  display: none;
  margin-top: -0.5rem;
  margin-bottom: 1rem;
  font-size: 0.75rem;
  font-weight: bold;
  color: #cc4b37; }
  .form-error.is-visible {
    display: block; }

.button {
  display: inline-block;
  vertical-align: middle;
  margin: 0 0 1rem 0;
  padding: 0.85em 1em;
  -webkit-appearance: none;
  border: 1px solid transparent;
  border-radius: 0;
  transition: background-color 0.25s ease-out, color 0.25s ease-out;
  font-size: 0.9rem;
  line-height: 1;
  text-align: center;
  cursor: pointer;
  background-color: #4A494A;
  color: #FAFAFA; }
  [data-whatinput='mouse'] .button {
    outline: 0; }
  .button:hover, .button:focus {
    background-color: #3f3e3f;
    color: #FAFAFA; }
  .button.tiny {
    font-size: 0.6rem; }
  .button.small {
    font-size: 0.75rem; }
  .button.large {
    font-size: 1.25rem; }
  .button.expanded {
    display: block;
    width: 100%;
    margin-right: 0;
    margin-left: 0; }
  .button.primary {
    background-color: #4A494A;
    color: #FAFAFA; }
    .button.primary:hover, .button.primary:focus {
      background-color: #3b3a3b;
      color: #FAFAFA; }
  .button.secondary {
    background-color: #c0c0c0;
    color: #212522; }
    .button.secondary:hover, .button.secondary:focus {
      background-color: #9a9a9a;
      color: #212522; }
  .button.success {
    background-color: #BCE048;
    color: #212522; }
    .button.success:hover, .button.success:focus {
      background-color: #a3ca22;
      color: #212522; }
  .button.warning {
    background-color: #ffae00;
    color: #212522; }
    .button.warning:hover, .button.warning:focus {
      background-color: #cc8b00;
      color: #212522; }
  .button.alert {
    background-color: #cc4b37;
    color: #FAFAFA; }
    .button.alert:hover, .button.alert:focus {
      background-color: #a53b2a;
      color: #FAFAFA; }
  .button.hollow {
    border: 1px solid #4A494A;
    color: #4A494A; }
    .button.hollow, .button.hollow:hover, .button.hollow:focus {
      background-color: transparent; }
    .button.hollow:hover, .button.hollow:focus {
      border-color: #252525;
      color: #252525; }
    .button.hollow.primary {
      border: 1px solid #4A494A;
      color: #4A494A; }
      .button.hollow.primary:hover, .button.hollow.primary:focus {
        border-color: #252525;
        color: #252525; }
    .button.hollow.secondary {
      border: 1px solid #c0c0c0;
      color: #c0c0c0; }
      .button.hollow.secondary:hover, .button.hollow.secondary:focus {
        border-color: #606060;
        color: #606060; }
    .button.hollow.success {
      border: 1px solid #BCE048;
      color: #BCE048; }
      .button.hollow.success:hover, .button.hollow.success:focus {
        border-color: #667f15;
        color: #667f15; }
    .button.hollow.warning {
      border: 1px solid #ffae00;
      color: #ffae00; }
      .button.hollow.warning:hover, .button.hollow.warning:focus {
        border-color: #805700;
        color: #805700; }
    .button.hollow.alert {
      border: 1px solid #cc4b37;
      color: #cc4b37; }
      .button.hollow.alert:hover, .button.hollow.alert:focus {
        border-color: #67251a;
        color: #67251a; }
  .button.disabled, .button[disabled] {
    opacity: 0.25;
    cursor: not-allowed; }
    .button.disabled, .button.disabled:hover, .button.disabled:focus, .button[disabled], .button[disabled]:hover, .button[disabled]:focus {
      background-color: #4A494A;
      color: #FAFAFA; }
    .button.disabled.primary, .button[disabled].primary {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.primary, .button.disabled.primary:hover, .button.disabled.primary:focus, .button[disabled].primary, .button[disabled].primary:hover, .button[disabled].primary:focus {
        background-color: #4A494A;
        color: #FAFAFA; }
    .button.disabled.secondary, .button[disabled].secondary {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.secondary, .button.disabled.secondary:hover, .button.disabled.secondary:focus, .button[disabled].secondary, .button[disabled].secondary:hover, .button[disabled].secondary:focus {
        background-color: #c0c0c0;
        color: #212522; }
    .button.disabled.success, .button[disabled].success {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.success, .button.disabled.success:hover, .button.disabled.success:focus, .button[disabled].success, .button[disabled].success:hover, .button[disabled].success:focus {
        background-color: #BCE048;
        color: #212522; }
    .button.disabled.warning, .button[disabled].warning {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.warning, .button.disabled.warning:hover, .button.disabled.warning:focus, .button[disabled].warning, .button[disabled].warning:hover, .button[disabled].warning:focus {
        background-color: #ffae00;
        color: #212522; }
    .button.disabled.alert, .button[disabled].alert {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.alert, .button.disabled.alert:hover, .button.disabled.alert:focus, .button[disabled].alert, .button[disabled].alert:hover, .button[disabled].alert:focus {
        background-color: #cc4b37;
        color: #FAFAFA; }
  .button.dropdown::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 0.4em;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #FAFAFA transparent transparent;
    position: relative;
    top: 0.4em;
    display: inline-block;
    float: right;
    margin-left: 1em; }
  .button.dropdown.hollow::after {
    border-top-color: #4A494A; }
  .button.dropdown.hollow.primary::after {
    border-top-color: #4A494A; }
  .button.dropdown.hollow.secondary::after {
    border-top-color: #c0c0c0; }
  .button.dropdown.hollow.success::after {
    border-top-color: #BCE048; }
  .button.dropdown.hollow.warning::after {
    border-top-color: #ffae00; }
  .button.dropdown.hollow.alert::after {
    border-top-color: #cc4b37; }
  .button.arrow-only::after {
    top: -0.1em;
    float: none;
    margin-left: 0; }

.breadcrumbs {
  margin: 0 0 1rem 0;
  list-style: none; }
  .breadcrumbs::before, .breadcrumbs::after {
    display: table;
    content: ' '; }
  .breadcrumbs::after {
    clear: both; }
  .breadcrumbs li {
    float: left;
    font-size: 0.6875rem;
    color: #212522;
    cursor: default;
    text-transform: uppercase; }
    .breadcrumbs li:not(:last-child)::after {
      position: relative;
      top: 1px;
      margin: 0 0.75rem;
      opacity: 1;
      content: "/";
      color: #e1e1e1; }
  .breadcrumbs a {
    color: #4A494A; }
    .breadcrumbs a:hover {
      text-decoration: underline; }
  .breadcrumbs .disabled {
    color: #e1e1e1;
    cursor: not-allowed; }

.button-group {
  margin-bottom: 1rem;
  font-size: 0; }
  .button-group::before, .button-group::after {
    display: table;
    content: ' '; }
  .button-group::after {
    clear: both; }
  .button-group .button {
    margin: 0;
    margin-right: 1px;
    margin-bottom: 1px;
    font-size: 0.9rem; }
    .button-group .button:last-child {
      margin-right: 0; }
  .button-group.tiny .button {
    font-size: 0.6rem; }
  .button-group.small .button {
    font-size: 0.75rem; }
  .button-group.large .button {
    font-size: 1.25rem; }
  .button-group.expanded {
    margin-right: -1px; }
    .button-group.expanded::before, .button-group.expanded::after {
      display: none; }
    .button-group.expanded .button:first-child:last-child {
      width: 100%; }
    .button-group.expanded .button:first-child:nth-last-child(2), .button-group.expanded .button:first-child:nth-last-child(2):first-child:nth-last-child(2) ~ .button {
      display: inline-block;
      width: calc(50% - 1px);
      margin-right: 1px; }
      .button-group.expanded .button:first-child:nth-last-child(2):last-child, .button-group.expanded .button:first-child:nth-last-child(2):first-child:nth-last-child(2) ~ .button:last-child {
        margin-right: -6px; }
    .button-group.expanded .button:first-child:nth-last-child(3), .button-group.expanded .button:first-child:nth-last-child(3):first-child:nth-last-child(3) ~ .button {
      display: inline-block;
      width: calc(33.33333% - 1px);
      margin-right: 1px; }
      .button-group.expanded .button:first-child:nth-last-child(3):last-child, .button-group.expanded .button:first-child:nth-last-child(3):first-child:nth-last-child(3) ~ .button:last-child {
        margin-right: -6px; }
    .button-group.expanded .button:first-child:nth-last-child(4), .button-group.expanded .button:first-child:nth-last-child(4):first-child:nth-last-child(4) ~ .button {
      display: inline-block;
      width: calc(25% - 1px);
      margin-right: 1px; }
      .button-group.expanded .button:first-child:nth-last-child(4):last-child, .button-group.expanded .button:first-child:nth-last-child(4):first-child:nth-last-child(4) ~ .button:last-child {
        margin-right: -6px; }
    .button-group.expanded .button:first-child:nth-last-child(5), .button-group.expanded .button:first-child:nth-last-child(5):first-child:nth-last-child(5) ~ .button {
      display: inline-block;
      width: calc(20% - 1px);
      margin-right: 1px; }
      .button-group.expanded .button:first-child:nth-last-child(5):last-child, .button-group.expanded .button:first-child:nth-last-child(5):first-child:nth-last-child(5) ~ .button:last-child {
        margin-right: -6px; }
    .button-group.expanded .button:first-child:nth-last-child(6), .button-group.expanded .button:first-child:nth-last-child(6):first-child:nth-last-child(6) ~ .button {
      display: inline-block;
      width: calc(16.66667% - 1px);
      margin-right: 1px; }
      .button-group.expanded .button:first-child:nth-last-child(6):last-child, .button-group.expanded .button:first-child:nth-last-child(6):first-child:nth-last-child(6) ~ .button:last-child {
        margin-right: -6px; }
  .button-group.primary .button {
    background-color: #4A494A;
    color: #FAFAFA; }
    .button-group.primary .button:hover, .button-group.primary .button:focus {
      background-color: #3b3a3b;
      color: #FAFAFA; }
  .button-group.secondary .button {
    background-color: #c0c0c0;
    color: #212522; }
    .button-group.secondary .button:hover, .button-group.secondary .button:focus {
      background-color: #9a9a9a;
      color: #212522; }
  .button-group.success .button {
    background-color: #BCE048;
    color: #212522; }
    .button-group.success .button:hover, .button-group.success .button:focus {
      background-color: #a3ca22;
      color: #212522; }
  .button-group.warning .button {
    background-color: #ffae00;
    color: #212522; }
    .button-group.warning .button:hover, .button-group.warning .button:focus {
      background-color: #cc8b00;
      color: #212522; }
  .button-group.alert .button {
    background-color: #cc4b37;
    color: #FAFAFA; }
    .button-group.alert .button:hover, .button-group.alert .button:focus {
      background-color: #a53b2a;
      color: #FAFAFA; }
  .button-group.stacked .button, .button-group.stacked-for-small .button, .button-group.stacked-for-medium .button {
    width: 100%; }
    .button-group.stacked .button:last-child, .button-group.stacked-for-small .button:last-child, .button-group.stacked-for-medium .button:last-child {
      margin-bottom: 0; }
  @media print, screen and (min-width: 40em) {
    .button-group.stacked-for-small .button {
      width: auto;
      margin-bottom: 0; } }
  @media print, screen and (min-width: 64.0625em) {
    .button-group.stacked-for-medium .button {
      width: auto;
      margin-bottom: 0; } }
  @media screen and (max-width: 39.9375em) {
    .button-group.stacked-for-small.expanded {
      display: block; }
      .button-group.stacked-for-small.expanded .button {
        display: block;
        margin-right: 0; } }

.close-button {
  position: absolute;
  color: #aaa;
  cursor: pointer; }
  [data-whatinput='mouse'] .close-button {
    outline: 0; }
  .close-button:hover, .close-button:focus {
    color: #212522; }
  .close-button.small {
    right: 0.66rem;
    top: 0.33em;
    font-size: 1.5em;
    line-height: 1; }
  .close-button, .close-button.medium {
    right: 1rem;
    top: 0.5rem;
    font-size: 2em;
    line-height: 1; }

.menu {
  margin: 0;
  list-style-type: none; }
  .menu > li {
    display: table-cell;
    vertical-align: middle; }
    [data-whatinput='mouse'] .menu > li {
      outline: 0; }
  .menu > li > a {
    display: block;
    padding: 0.7rem 1rem;
    line-height: 1; }
  .menu input,
  .menu select,
  .menu a,
  .menu button {
    margin-bottom: 0; }
  .menu > li > a img,
  .menu > li > a i,
  .menu > li > a svg {
    vertical-align: middle; }
    .menu > li > a img + span,
    .menu > li > a i + span,
    .menu > li > a svg + span {
      vertical-align: middle; }
  .menu > li > a img,
  .menu > li > a i,
  .menu > li > a svg {
    margin-right: 0.25rem;
    display: inline-block; }
  .menu > li, .menu.horizontal > li {
    display: table-cell; }
  .menu.expanded {
    display: table;
    width: 100%;
    table-layout: fixed; }
    .menu.expanded > li:first-child:last-child {
      width: 100%; }
  .menu.vertical > li {
    display: block; }
  @media print, screen and (min-width: 40em) {
    .menu.medium-horizontal > li {
      display: table-cell; }
    .menu.medium-expanded {
      display: table;
      width: 100%;
      table-layout: fixed; }
      .menu.medium-expanded > li:first-child:last-child {
        width: 100%; }
    .menu.medium-vertical > li {
      display: block; } }
  @media print, screen and (min-width: 64.0625em) {
    .menu.large-horizontal > li {
      display: table-cell; }
    .menu.large-expanded {
      display: table;
      width: 100%;
      table-layout: fixed; }
      .menu.large-expanded > li:first-child:last-child {
        width: 100%; }
    .menu.large-vertical > li {
      display: block; } }
  .menu.simple li {
    display: inline-block;
    vertical-align: top;
    line-height: 1; }
  .menu.simple a {
    padding: 0; }
  .menu.simple li {
    margin-left: 0;
    margin-right: 1rem; }
  .menu.simple.align-right li {
    margin-right: 0;
    margin-left: 1rem; }
  .menu.align-right::before, .menu.align-right::after {
    display: table;
    content: ' '; }
  .menu.align-right::after {
    clear: both; }
  .menu.align-right > li {
    float: right; }
  .menu.icon-top > li > a {
    text-align: center; }
    .menu.icon-top > li > a img,
    .menu.icon-top > li > a i,
    .menu.icon-top > li > a svg {
      display: block;
      margin: 0 auto 0.25rem; }
  .menu.icon-top.vertical a > span {
    margin: auto; }
  .menu.nested {
    margin-left: 1rem; }
  .menu .active > a {
    background: #4A494A;
    color: #FAFAFA; }
  .menu.menu-bordered li {
    border: 1px solid #e6e6e6; }
    .menu.menu-bordered li:not(:first-child) {
      border-top: 0; }
  .menu.menu-hover li:hover {
    background-color: #e6e6e6; }

.menu-text {
  padding-top: 0;
  padding-bottom: 0;
  padding: 0.7rem 1rem;
  font-weight: bold;
  line-height: 1;
  color: inherit; }

.menu-centered {
  text-align: center; }
  .menu-centered > .menu {
    display: inline-block;
    vertical-align: top; }

.no-js [data-responsive-menu] ul {
  display: none; }

.menu-icon {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 16px;
  cursor: pointer; }
  .menu-icon::after {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 2px;
    background: #FAFAFA;
    box-shadow: 0 7px 0 #FAFAFA, 0 14px 0 #FAFAFA;
    content: ''; }
  .menu-icon:hover::after {
    background: #e1e1e1;
    box-shadow: 0 7px 0 #e1e1e1, 0 14px 0 #e1e1e1; }

.menu-icon.dark {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 16px;
  cursor: pointer; }
  .menu-icon.dark::after {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 2px;
    background: #212522;
    box-shadow: 0 7px 0 #212522, 0 14px 0 #212522;
    content: ''; }
  .menu-icon.dark:hover::after {
    background: #aaa;
    box-shadow: 0 7px 0 #aaa, 0 14px 0 #aaa; }

.is-drilldown {
  position: relative;
  overflow: hidden; }
  .is-drilldown li {
    display: block; }
  .is-drilldown.animate-height {
    transition: height 0.5s; }

.is-drilldown-submenu {
  position: absolute;
  top: 0;
  left: 100%;
  z-index: -1;
  width: 100%;
  background: #FAFAFA;
  transition: transform 0.15s linear; }
  .is-drilldown-submenu.is-active {
    z-index: 1;
    display: block;
    transform: translateX(-100%); }
  .is-drilldown-submenu.is-closing {
    transform: translateX(100%); }

.drilldown-submenu-cover-previous {
  min-height: 100%; }

.is-drilldown-submenu-parent > a {
  position: relative; }
  .is-drilldown-submenu-parent > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #4A494A;
    position: absolute;
    top: 50%;
    margin-top: -6px;
    right: 1rem; }

.js-drilldown-back > a::before {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-left-width: 0;
  border-right-style: solid;
  border-color: transparent #4A494A transparent transparent;
  border-left-width: 0;
  display: inline-block;
  vertical-align: middle;
  margin-right: 0.75rem;
  border-left-width: 0; }

.dropdown-pane {
  position: absolute;
  z-index: 10;
  display: block;
  width: 300px;
  padding: 1rem;
  visibility: hidden;
  border: 1px solid #e1e1e1;
  border-radius: 0;
  background-color: white;
  font-size: 1rem; }
  .dropdown-pane.is-open {
    visibility: visible; }

.dropdown-pane.tiny {
  width: 100px; }

.dropdown-pane.small {
  width: 200px; }

.dropdown-pane.large {
  width: 400px; }

.dropdown.menu > li.opens-left > .is-dropdown-submenu {
  top: 100%;
  right: 0;
  left: auto; }

.dropdown.menu > li.opens-right > .is-dropdown-submenu {
  top: 100%;
  right: auto;
  left: 0; }

.dropdown.menu > li.is-dropdown-submenu-parent > a {
  position: relative;
  padding-right: 1.5rem; }

.dropdown.menu > li.is-dropdown-submenu-parent > a::after {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-bottom-width: 0;
  border-top-style: solid;
  border-color: #4A494A transparent transparent;
  right: 5px;
  margin-top: -3px; }

[data-whatinput='mouse'] .dropdown.menu a {
  outline: 0; }

.no-js .dropdown.menu ul {
  display: none; }

.dropdown.menu.vertical > li .is-dropdown-submenu {
  top: 0; }

.dropdown.menu.vertical > li.opens-left > .is-dropdown-submenu {
  right: 100%;
  left: auto; }

.dropdown.menu.vertical > li.opens-right > .is-dropdown-submenu {
  right: auto;
  left: 100%; }

.dropdown.menu.vertical > li > a::after {
  right: 14px; }

.dropdown.menu.vertical > li.opens-left > a::after {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-left-width: 0;
  border-right-style: solid;
  border-color: transparent #4A494A transparent transparent; }

.dropdown.menu.vertical > li.opens-right > a::after {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-right-width: 0;
  border-left-style: solid;
  border-color: transparent transparent transparent #4A494A; }

@media print, screen and (min-width: 40em) {
  .dropdown.menu.medium-horizontal > li.opens-left > .is-dropdown-submenu {
    top: 100%;
    right: 0;
    left: auto; }
  .dropdown.menu.medium-horizontal > li.opens-right > .is-dropdown-submenu {
    top: 100%;
    right: auto;
    left: 0; }
  .dropdown.menu.medium-horizontal > li.is-dropdown-submenu-parent > a {
    position: relative;
    padding-right: 1.5rem; }
  .dropdown.menu.medium-horizontal > li.is-dropdown-submenu-parent > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #4A494A transparent transparent;
    right: 5px;
    margin-top: -3px; }
  .dropdown.menu.medium-vertical > li .is-dropdown-submenu {
    top: 0; }
  .dropdown.menu.medium-vertical > li.opens-left > .is-dropdown-submenu {
    right: 100%;
    left: auto; }
  .dropdown.menu.medium-vertical > li.opens-right > .is-dropdown-submenu {
    right: auto;
    left: 100%; }
  .dropdown.menu.medium-vertical > li > a::after {
    right: 14px; }
  .dropdown.menu.medium-vertical > li.opens-left > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent #4A494A transparent transparent; }
  .dropdown.menu.medium-vertical > li.opens-right > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #4A494A; } }

@media print, screen and (min-width: 64.0625em) {
  .dropdown.menu.large-horizontal > li.opens-left > .is-dropdown-submenu {
    top: 100%;
    right: 0;
    left: auto; }
  .dropdown.menu.large-horizontal > li.opens-right > .is-dropdown-submenu {
    top: 100%;
    right: auto;
    left: 0; }
  .dropdown.menu.large-horizontal > li.is-dropdown-submenu-parent > a {
    position: relative;
    padding-right: 1.5rem; }
  .dropdown.menu.large-horizontal > li.is-dropdown-submenu-parent > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #4A494A transparent transparent;
    right: 5px;
    margin-top: -3px; }
  .dropdown.menu.large-vertical > li .is-dropdown-submenu {
    top: 0; }
  .dropdown.menu.large-vertical > li.opens-left > .is-dropdown-submenu {
    right: 100%;
    left: auto; }
  .dropdown.menu.large-vertical > li.opens-right > .is-dropdown-submenu {
    right: auto;
    left: 100%; }
  .dropdown.menu.large-vertical > li > a::after {
    right: 14px; }
  .dropdown.menu.large-vertical > li.opens-left > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent #4A494A transparent transparent; }
  .dropdown.menu.large-vertical > li.opens-right > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #4A494A; } }

.dropdown.menu.align-right .is-dropdown-submenu.first-sub {
  top: 100%;
  right: 0;
  left: auto; }

.is-dropdown-menu.vertical {
  width: 100px; }
  .is-dropdown-menu.vertical.align-right {
    float: right; }

.is-dropdown-submenu-parent {
  position: relative; }
  .is-dropdown-submenu-parent a::after {
    position: absolute;
    top: 50%;
    right: 5px;
    margin-top: -6px; }
  .is-dropdown-submenu-parent.opens-inner > .is-dropdown-submenu {
    top: 100%;
    left: auto; }
  .is-dropdown-submenu-parent.opens-left > .is-dropdown-submenu {
    right: 100%;
    left: auto; }
  .is-dropdown-submenu-parent.opens-right > .is-dropdown-submenu {
    right: auto;
    left: 100%; }

.is-dropdown-submenu {
  position: absolute;
  top: 0;
  left: 100%;
  z-index: 1;
  display: none;
  min-width: 200px;
  border: 1px solid #e1e1e1;
  background: #FAFAFA; }
  .is-dropdown-submenu .is-dropdown-submenu-parent > a::after {
    right: 14px; }
  .is-dropdown-submenu .is-dropdown-submenu-parent.opens-left > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent #4A494A transparent transparent; }
  .is-dropdown-submenu .is-dropdown-submenu-parent.opens-right > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #4A494A; }
  .is-dropdown-submenu .is-dropdown-submenu {
    margin-top: -1px; }
  .is-dropdown-submenu > li {
    width: 100%; }
  .is-dropdown-submenu.js-dropdown-active {
    display: block; }

.responsive-embed,
.flex-video {
  position: relative;
  height: 0;
  margin-bottom: 1rem;
  padding-bottom: 75%;
  overflow: hidden; }
  .responsive-embed iframe,
  .responsive-embed object,
  .responsive-embed embed,
  .responsive-embed video,
  .flex-video iframe,
  .flex-video object,
  .flex-video embed,
  .flex-video video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
  .responsive-embed.widescreen,
  .flex-video.widescreen {
    padding-bottom: 56.25%; }

.label {
  display: inline-block;
  padding: 0.33333rem 0.5rem;
  border-radius: 0;
  font-size: 0.8rem;
  line-height: 1;
  white-space: nowrap;
  cursor: default;
  background: #4A494A;
  color: #FAFAFA; }
  .label.primary {
    background: #4A494A;
    color: #FAFAFA; }
  .label.secondary {
    background: #c0c0c0;
    color: #212522; }
  .label.success {
    background: #BCE048;
    color: #212522; }
  .label.warning {
    background: #ffae00;
    color: #212522; }
  .label.alert {
    background: #cc4b37;
    color: #FAFAFA; }

.media-object {
  display: block;
  margin-bottom: 1rem; }
  .media-object img {
    max-width: none; }
  @media screen and (max-width: 39.9375em) {
    .media-object.stack-for-small .media-object-section {
      padding: 0;
      padding-bottom: 1rem;
      display: block; }
      .media-object.stack-for-small .media-object-section img {
        width: 100%; } }

.media-object-section {
  display: table-cell;
  vertical-align: top; }
  .media-object-section:first-child {
    padding-right: 1rem; }
  .media-object-section:last-child:not(:nth-child(2)) {
    padding-left: 1rem; }
  .media-object-section > :last-child {
    margin-bottom: 0; }
  .media-object-section.middle {
    vertical-align: middle; }
  .media-object-section.bottom {
    vertical-align: bottom; }

.title-bar {
  padding: 0.5rem;
  background: #212522;
  color: #FAFAFA; }
  .title-bar::before, .title-bar::after {
    display: table;
    content: ' '; }
  .title-bar::after {
    clear: both; }
  .title-bar .menu-icon {
    margin-left: 0.25rem;
    margin-right: 0.25rem; }

.title-bar-left {
  float: left; }

.title-bar-right {
  float: right;
  text-align: right; }

.title-bar-title {
  display: inline-block;
  vertical-align: middle;
  font-weight: bold; }

.has-tip {
  position: relative;
  display: inline-block;
  border-bottom: dotted 1px #aaa;
  font-weight: bold;
  cursor: help; }

.tooltip {
  position: absolute;
  top: calc(100% + 0.6495rem);
  z-index: 1200;
  max-width: 10rem;
  padding: 0.75rem;
  border-radius: 0;
  background-color: #212522;
  font-size: 80%;
  color: #FAFAFA; }
  .tooltip::before {
    display: block;
    width: 0;
    height: 0;
    border: inset 0.75rem;
    content: '';
    border-top-width: 0;
    border-bottom-style: solid;
    border-color: transparent transparent #212522;
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%); }
  .tooltip.top::before {
    display: block;
    width: 0;
    height: 0;
    border: inset 0.75rem;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #212522 transparent transparent;
    top: 100%;
    bottom: auto; }
  .tooltip.left::before {
    display: block;
    width: 0;
    height: 0;
    border: inset 0.75rem;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #212522;
    top: 50%;
    bottom: auto;
    left: 100%;
    transform: translateY(-50%); }
  .tooltip.right::before {
    display: block;
    width: 0;
    height: 0;
    border: inset 0.75rem;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent #212522 transparent transparent;
    top: 50%;
    right: 100%;
    bottom: auto;
    left: auto;
    transform: translateY(-50%); }

.top-bar {
  padding: 0.5rem; }
  .top-bar::before, .top-bar::after {
    display: table;
    content: ' '; }
  .top-bar::after {
    clear: both; }
  .top-bar,
  .top-bar ul {
    background-color: #e6e6e6; }
  .top-bar input {
    max-width: 200px;
    margin-right: 1rem; }
  .top-bar .input-group-field {
    width: 100%;
    margin-right: 0; }
  .top-bar input.button {
    width: auto; }
  .top-bar .top-bar-left,
  .top-bar .top-bar-right {
    width: 100%; }
  @media print, screen and (min-width: 40em) {
    .top-bar .top-bar-left,
    .top-bar .top-bar-right {
      width: auto; } }
  @media screen and (max-width: 64em) {
    .top-bar.stacked-for-medium .top-bar-left,
    .top-bar.stacked-for-medium .top-bar-right {
      width: 100%; } }
  @media screen and (max-width: 74.9375em) {
    .top-bar.stacked-for-large .top-bar-left,
    .top-bar.stacked-for-large .top-bar-right {
      width: 100%; } }

.top-bar-title {
  display: inline-block;
  float: left;
  padding: 0.5rem 1rem 0.5rem 0; }
  .top-bar-title .menu-icon {
    bottom: 2px; }

.top-bar-left {
  float: left; }

.top-bar-right {
  float: right; }

.hide {
  display: none !important; }

.invisible {
  visibility: hidden; }

@media screen and (max-width: 39.9375em) {
  .hide-for-small-only {
    display: none !important; } }

@media screen and (max-width: 0em), screen and (min-width: 40em) {
  .show-for-small-only {
    display: none !important; } }

@media print, screen and (min-width: 40em) {
  .hide-for-medium {
    display: none !important; } }

@media screen and (max-width: 39.9375em) {
  .show-for-medium {
    display: none !important; } }

@media screen and (min-width: 40em) and (max-width: 64em) {
  .hide-for-medium-only {
    display: none !important; } }

@media screen and (max-width: 39.9375em), screen and (min-width: 64.0625em) {
  .show-for-medium-only {
    display: none !important; } }

@media print, screen and (min-width: 64.0625em) {
  .hide-for-large {
    display: none !important; } }

@media screen and (max-width: 64em) {
  .show-for-large {
    display: none !important; } }

@media screen and (min-width: 64.0625em) and (max-width: 74.9375em) {
  .hide-for-large-only {
    display: none !important; } }

@media screen and (max-width: 64em), screen and (min-width: 75em) {
  .show-for-large-only {
    display: none !important; } }

.show-for-sr,
.show-on-focus {
  position: absolute !important;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0); }

.show-on-focus:active, .show-on-focus:focus {
  position: static !important;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto; }

.show-for-landscape,
.hide-for-portrait {
  display: block !important; }
  @media screen and (orientation: landscape) {
    .show-for-landscape,
    .hide-for-portrait {
      display: block !important; } }
  @media screen and (orientation: portrait) {
    .show-for-landscape,
    .hide-for-portrait {
      display: none !important; } }

.hide-for-landscape,
.show-for-portrait {
  display: none !important; }
  @media screen and (orientation: landscape) {
    .hide-for-landscape,
    .show-for-portrait {
      display: none !important; } }
  @media screen and (orientation: portrait) {
    .hide-for-landscape,
    .show-for-portrait {
      display: block !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-center {
  display: block;
  margin-right: auto;
  margin-left: auto; }

.clearfix::before, .clearfix::after {
  display: table;
  content: ' '; }

.clearfix::after {
  clear: both; }

@font-face {
  font-family: freehand;
  src: url(/sites/all/themes/gensel/font/freehand-471-bt-589da0f0d895a.ttf); }

h2 {
  font-size: 2rem;
  font-family: SourceSansPro-Regular; }
  h2.section-heading {
    font-size: 1.5625rem;
    text-transform: uppercase;
    color: #4A494A; }

.underline {
  height: 2px;
  background-color: #BCE048;
  max-width: 280px;
  margin: 0 auto 60px auto; }

.section {
  padding: 70px 0; }

a {
  color: white; }

p {
  margin: 0; }

main {
  background-color: #FAFAFA; }

body {
  background-color: #4A494A;
  overflow-x: hidden; }

textarea {
  resize: none !important;
  opacity: 1 !important; }

.breite {
  width: 80%;
  max-width: 1440px;
  margin: 0 auto; }
  @media screen and (max-width: 89.9375em) {
    .breite {
      max-width: none;
      width: 100%; } }

.paragraph-text {
  width: 80%; }
  @media screen and (max-width: 64em) {
    .paragraph-text {
      width: 100%; } }
  .paragraph-text a {
    color: black; }
    .paragraph-text a:hover {
      color: #4A494A; }

.grippie {
  display: none !important; }

.tabs {
  padding: 15px 0 0 0 !important; }

.block-menu {
  background-color: #4A494A;
  height: 75px; }
  @media screen and (max-width: 64em) {
    .block-menu {
      height: 45px; } }
  .block-menu .menu {
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative; }
    .block-menu .menu .leaf {
      margin: 0;
      height: 100%;
      border-left: 1px solid #a2a2a2;
      padding: 0; }
    .block-menu .menu a {
      text-transform: uppercase;
      color: #edeae7;
      padding: 1.76rem 2.5em; }
      .block-menu .menu a.active {
        border-bottom: 3px solid #BCE048;
        color: white; }
    .block-menu .menu .first {
      border-left: 0 solid #a2a2a2;
      height: 57px;
      width: 40px;
      margin-right: 15px;
      text-indent: 100%;
      white-space: nowrap;
      overflow: hidden; }
      .block-menu .menu .first a {
        background-image: url("/sites/all/themes/gensel/img/logo.svg");
        filter: grayscale(100%);
        background-size: contain;
        margin-right: -50px;
        height: 50px; }
        .block-menu .menu .first a.active {
          border-bottom: 0 solid #BCE048; }
    .block-menu .menu .last {
      border-right: 1px solid #a2a2a2; }

span.toggler {
  float: left;
  margin: 5px;
  border: none;
  box-shadow: 1px 1px 1px transparent !important;
  background-color: transparent !important; }

@media screen and (max-width: 64em) {
  #rm-removed {
    margin-top: 45px !important; } }

#rm-removed li.first {
  display: none !important; }

.side-menu {
  z-index: 99;
  position: fixed;
  text-align: right;
  top: 35%;
  left: 91%;
  width: 100px;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.6s; }
  @media screen and (max-width: 1439px) {
    .side-menu {
      display: none; } }
  .side-menu.show {
    opacity: 1;
    pointer-events: all; }
  .side-menu .side-menu-logo {
    background-size: cover;
    width: 25px;
    height: 40px;
    margin-left: 82px; }
  .side-menu .side-menu-item {
    display: inline-block;
    opacity: 0.65;
    padding-right: 10px;
    font-size: 14px;
    letter-spacing: 0.4px;
    margin-top: 23px; }
    .side-menu .side-menu-item a {
      position: relative;
      color: #FAFAFA;
      transition: all 0.3s; }
      .side-menu .side-menu-item a:after {
        content: '';
        position: absolute;
        transition: all 0.5s;
        left: 0;
        width: 100%;
        height: 2px;
        background: #FAFAFA;
        -webkit-transform: scale(0, 1);
        -moz-transform: scale(0, 1);
        -ms-transform: scale(0, 1);
        -o-transform: scale(0, 1);
        transform: scale(0, 1); }
    .side-menu .side-menu-item.active a:after {
      -webkit-transform: scale(1, 1);
      -moz-transform: scale(1, 1);
      -ms-transform: scale(1, 1);
      -o-transform: scale(1, 1);
      transform: scale(1, 1); }
  .side-menu .linie {
    float: right;
    display: inline-block;
    height: 3px;
    width: 10px;
    background-color: grey;
    margin-top: 33px; }
  .side-menu .linien {
    position: fixed;
    width: 5px;
    transform: scaleY(0.9);
    margin-top: -148px;
    margin-left: 92px; }

.header {
  background-image: url("/sites/all/themes/gensel/img/header-background.png");
  height: 800px;
  width: 100%;
  margin: 0 auto;
  background-size: cover;
  display: block;
  position: relative;
  overflow: hidden; }
  @media screen and (max-width: 64em) {
    .header {
      height: 500px; } }
  .header .logo-wrapper {
    margin: 100px auto 0 auto;
    width: 203px; }
    @media screen and (max-width: 64em) {
      .header .logo-wrapper {
        width: 103px; } }
    .header .logo-wrapper .logo {
      background-image: url("/sites/all/themes/gensel/img/header_logo-gensel.png");
      width: 200px;
      height: 203px;
      margin: 140px 0 0 0;
      background-size: contain;
      display: block;
      position: relative;
      transform: translateX(13px); }
      @media screen and (max-width: 64em) {
        .header .logo-wrapper .logo {
          width: 100px;
          height: 103px; } }
  .header .text-wrapper {
    width: 50%;
    margin: 10px auto 0 auto; }
    @media screen and (max-width: 64em) {
      .header .text-wrapper {
        width: 100%; } }
    .header .text-wrapper .titel-small {
      font-family: SourceSansPro-Regular;
      text-align: center;
      margin: 30px 0 20px 0;
      font-size: 25px;
      color: #4A494A;
      letter-spacing: 1.5px; }
    .header .text-wrapper .titel {
      font-family: freehand;
      text-align: center;
      font-size: 80px;
      color: #4A494A;
      letter-spacing: 2px;
      line-height: 1;
      margin: 0 auto; }
      @media screen and (min-width: 40em) and (max-width: 64em) {
        .header .text-wrapper .titel {
          font-size: 60px; } }
      @media screen and (max-width: 39.9375em) {
        .header .text-wrapper .titel {
          font-size: 42px; } }

.field-name-field-uberschrift {
  font-family: SourceSansPro-Regular;
  text-align: center;
  font-size: 40px;
  color: #4A494A;
  letter-spacing: 1.14px;
  margin: 70px 0 60px 0; }
  @media screen and (max-width: 64em) {
    .field-name-field-uberschrift {
      margin: 40px 0 30px 0; } }
  @media screen and (max-width: 39.9375em) {
    .field-name-field-uberschrift {
      font-size: 28px !important;
      margin: 30px 0 20px 0; } }
  .field-name-field-uberschrift:after {
    content: "";
    display: block;
    margin: 0 auto;
    width: 303px;
    height: 2px;
    background-color: #BCE048; }
    @media screen and (max-width: 39.9375em) {
      .field-name-field-uberschrift:after {
        width: 210px; } }

.paragraphs-item-text-uberschrift-grey {
  background-color: #d8d0cd; }
  .paragraphs-item-text-uberschrift-grey .field-name-field-uberschrift {
    font-family: SourceSansPro-Regular;
    padding: 50px 0;
    text-align: center;
    font-size: 40px;
    color: #4A494A;
    letter-spacing: 1.14px;
    margin-bottom: 0; }
    @media screen and (max-width: 39.9375em) {
      .paragraphs-item-text-uberschrift-grey .field-name-field-uberschrift {
        font-size: 20px;
        padding: 30px 0 20px; } }
    .paragraphs-item-text-uberschrift-grey .field-name-field-uberschrift:before {
      content: "";
      display: block;
      width: 0;
      height: 0;
      border-left: 20px solid transparent;
      border-right: 20px solid transparent;
      border-top: 20px solid #FAFAFA;
      margin: -52px auto 50px auto; }
      @media screen and (max-width: 39.9375em) {
        .paragraphs-item-text-uberschrift-grey .field-name-field-uberschrift:before {
          margin: -31px auto 50px auto; } }
    .paragraphs-item-text-uberschrift-grey .field-name-field-uberschrift:after {
      content: "";
      display: block;
      margin: 0 auto;
      width: 303px;
      height: 2px;
      background-color: #4A494A; }

.paragraphs-item-bild-mit-text .field-name-field-bild {
  display: inline-block;
  width: 50%; }
  @media screen and (max-width: 64em) {
    .paragraphs-item-bild-mit-text .field-name-field-bild {
      width: 100%; } }
  @media screen and (min-width: 40em) and (max-width: 64em) {
    .paragraphs-item-bild-mit-text .field-name-field-bild img {
      padding: 10px; } }

.paragraphs-item-bild-mit-text .field-name-field-text {
  display: inline-block;
  width: 40%;
  padding: 20px 40px;
  vertical-align: top; }
  @media screen and (max-width: 64em) {
    .paragraphs-item-bild-mit-text .field-name-field-text {
      width: 100%;
      font-size: 12px;
      line-height: 20px; } }
  .paragraphs-item-bild-mit-text .field-name-field-text div {
    margin: 0 auto; }

#node-6 .paragraphs-item-bild img {
  width: 100%; }
  @media screen and (max-width: 64em) {
    #node-6 .paragraphs-item-bild img {
      height: 300px; } }
  @media screen and (max-width: 39.9375em) {
    #node-6 .paragraphs-item-bild img {
      height: 150px; } }

.view-person-ohne-bild .view-content {
  margin: 50px 0 100px 0;
  display: flex;
  flex-flow: row wrap;
  justify-content: center; }
  .view-person-ohne-bild .view-content .views-row {
    width: 300px;
    padding: 15px; }
    .view-person-ohne-bild .view-content .views-row .node-person:before {
      content: '';
      width: 2px;
      height: 50px;
      background-color: #BCE048;
      position: relative;
      top: 68px;
      right: 10px; }
    .view-person-ohne-bild .view-content .views-row .node-person .field-name-field-bild img {
      display: none; }
    .view-person-ohne-bild .view-content .views-row .node-person .field-name-field-vorname {
      display: inline-block;
      font-weight: 200;
      font-size: 22px;
      padding: 2px 0; }
    .view-person-ohne-bild .view-content .views-row .node-person .field-name-field-nachname {
      font-weight: bold;
      display: inline-block;
      margin-left: 5px;
      font-size: 22px;
      padding: 5px 0; }
    .view-person-ohne-bild .view-content .views-row .node-person .field-name-field-beruf {
      font-size: 18px;
      font-weight: lighter;
      padding: 5px 0; }
      .view-person-ohne-bild .view-content .views-row .node-person .field-name-field-beruf a {
        color: #212522;
        pointer-events: none !important; }
    .view-person-ohne-bild .view-content .views-row .node-person .field-name-field-email {
      display: inline-block;
      width: 300px;
      padding: 5px 0; }
      .view-person-ohne-bild .view-content .views-row .node-person .field-name-field-email .field-items {
        display: inherit; }
        .view-person-ohne-bild .view-content .views-row .node-person .field-name-field-email .field-items .field-item a {
          color: #212522;
          margin-left: 6px; }
      .view-person-ohne-bild .view-content .views-row .node-person .field-name-field-email:before {
        display: inline-block;
        content: "  ";
        width: 30px;
        height: 20px;
        background-size: cover;
        background-image: url("/sites/all/themes/gensel/img/icon-email.svg"); }
    .view-person-ohne-bild .view-content .views-row .node-person .field-name-field-telefon {
      display: inline-block;
      width: 300px; }
      .view-person-ohne-bild .view-content .views-row .node-person .field-name-field-telefon .field-items {
        display: inherit; }
        .view-person-ohne-bild .view-content .views-row .node-person .field-name-field-telefon .field-items .field-item {
          color: #212522;
          margin-left: 10px; }
      .view-person-ohne-bild .view-content .views-row .node-person .field-name-field-telefon:before {
        display: inline-block;
        content: "  ";
        width: 25px;
        height: 25px;
        background-size: cover;
        background-image: url("/sites/all/themes/gensel/img/icon-telefon.svg"); }

.view-taxonomie-ansicht-leistungen {
  margin: 20px 0; }
  .view-taxonomie-ansicht-leistungen .taxonomy-term {
    position: relative;
    width: 155px;
    height: 150px;
    padding: 20px 20px;
    cursor: pointer;
    text-align: center;
    transition: all 0.6s; }
    .view-taxonomie-ansicht-leistungen .taxonomy-term:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 2px;
      left: 0;
      top: 0;
      background: #BCE048;
      transition: all 0.6s;
      transform: scaleX(0); }
    .view-taxonomie-ansicht-leistungen .taxonomy-term:hover {
      color: #BCE048; }
      .view-taxonomie-ansicht-leistungen .taxonomy-term:hover:before {
        transform: scaleX(1); }
    .view-taxonomie-ansicht-leistungen .taxonomy-term.active {
      background-color: #BCE048;
      color: white; }
  .view-taxonomie-ansicht-leistungen .field-type-image {
    background-size: cover;
    height: 53px;
    width: 53px;
    margin: 10px auto; }
  .view-taxonomie-ansicht-leistungen .term-name {
    font-size: 1rem;
    padding: 15px 0; }

.thumbnail_referenz {
  width: 50px;
  height: 50px;
  display: none;
  border-radius: 50%;
  margin: 10px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  cursor: pointer;
  border: 1px solid #FFFFFF;
  box-shadow: 1px 1px 1px 0 rgba(0, 0, 0, 0.5);
  transition: box-shadow 0.3s;
  filter: grayscale(100%); }
  .thumbnail_referenz.active {
    filter: none;
    box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.3); }
  .thumbnail_referenz.visible {
    display: block; }

.titel-thumbnail-referenzen {
  display: none; }

.slider-projekt {
  display: none;
  max-width: 1000px;
  margin: 20px auto 0; }
  .slider-projekt .item-projekt {
    position: relative; }
    .slider-projekt .item-projekt .item-images {
      position: relative;
      width: 100%;
      height: 320px;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center; }
    .slider-projekt .item-projekt .owl-text .text-wrapper {
      background: none;
      position: relative;
      width: 275px;
      color: black;
      padding: 50px 15px 0 15px;
      margin: 0 auto; }
      .slider-projekt .item-projekt .owl-text .text-wrapper .titel {
        font-family: SourceSansPro-Bold;
        letter-spacing: 1.14px;
        display: block;
        font-size: 25px; }
      .slider-projekt .item-projekt .owl-text .text-wrapper .subtitle {
        font-family: SourceSansPro-Light;
        font-size: 15px; }
      .slider-projekt .item-projekt .owl-text .text-wrapper .text {
        font-family: SourceSansPro-Regular;
        font-size: 15px;
        letter-spacing: 0.42px;
        display: block;
        padding-top: 20px;
        padding-bottom: 15px; }
        .slider-projekt .item-projekt .owl-text .text-wrapper .text li {
          margin-bottom: 5px;
          line-height: 1.4; }
      .slider-projekt .item-projekt .owl-text .text-wrapper .button-wrapper {
        position: relative;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center; }
        .slider-projekt .item-projekt .owl-text .text-wrapper .button-wrapper .button {
          border: 1px solid #BCE048;
          border-radius: 4.48px;
          color: #FAFAFA;
          width: 200px;
          height: 40px; }
          .slider-projekt .item-projekt .owl-text .text-wrapper .button-wrapper .button.one {
            background: #BCE048; }
          .slider-projekt .item-projekt .owl-text .text-wrapper .button-wrapper .button.two {
            background: #4A494A; }
  .slider-projekt .owl-nav {
    position: absolute;
    width: 100%;
    top: 150px;
    left: 0;
    color: #FAFAFA; }
    .slider-projekt .owl-nav .owl-prev {
      float: left;
      height: 26px;
      width: 26px;
      text-align: center;
      transform: scale(1.5);
      margin-left: 20px;
      background-color: rgba(82, 82, 82, 0.66);
      border: 1px solid transparent;
      border-radius: 50%; }
    .slider-projekt .owl-nav .owl-next {
      float: right;
      height: 26px;
      width: 26px;
      text-align: center;
      transform: scale(1.5);
      margin-right: 20px;
      background-color: rgba(82, 82, 82, 0.66);
      border: 1px solid transparent;
      border-radius: 50%; }
  .slider-projekt .owl-dots {
    position: absolute;
    width: 100%;
    top: 335px;
    left: 0;
    display: flex;
    justify-content: center;
    flex-wrap: wrap; }
    .slider-projekt .owl-dots .owl-dot {
      border: 1px solid grey;
      border-radius: 5px;
      background: #FAFAFA;
      height: 10px;
      width: 10px;
      margin: 5px; }
      .slider-projekt .owl-dots .owl-dot.active {
        background: grey;
        border: 1px solid grey; }

.node-firmengeschichte {
  width: 90%;
  margin: 0 auto; }
  .node-firmengeschichte .owl-logo {
    position: relative;
    text-align: center;
    margin: 0 auto; }
    @media screen and (max-width: 64em) {
      .node-firmengeschichte .owl-logo {
        margin-bottom: 30px;
        transform: scale(1.2); } }
    @media screen and (max-width: 39.9375em) {
      .node-firmengeschichte .owl-logo {
        margin-bottom: 30px;
        transform: scale(1); } }
    .node-firmengeschichte .owl-logo .jahr {
      display: inline-block;
      vertical-align: middle;
      height: 208px;
      margin-right: 25px;
      font-size: 36px;
      color: #4A494A;
      letter-spacing: 1.02px;
      font-weight: 700; }
      .node-firmengeschichte .owl-logo .jahr.right {
        margin-left: -15px; }
        @media screen and (max-width: 64em) {
          .node-firmengeschichte .owl-logo .jahr.right {
            margin-left: -5px; } }
      @media screen and (max-width: 64em) {
        .node-firmengeschichte .owl-logo .jahr {
          height: 60px;
          margin-right: 10px;
          font-size: 20px; } }
    .node-firmengeschichte .owl-logo .logo {
      display: inline-block;
      width: 197px;
      height: 200px;
      background-size: contain; }
      @media screen and (max-width: 64em) {
        .node-firmengeschichte .owl-logo .logo {
          width: 98px;
          height: 100px; } }
    .node-firmengeschichte .owl-logo .line-green {
      height: 5px;
      width: 90%;
      margin: 0 auto;
      background-color: #BCE048; }
      @media screen and (max-width: 64em) {
        .node-firmengeschichte .owl-logo .line-green {
          width: 75%; } }
      @media screen and (max-width: 55em) {
        .node-firmengeschichte .owl-logo .line-green {
          width: 70%; } }
      .node-firmengeschichte .owl-logo .line-green:before {
        content: " ";
        background: #BCE048;
        border: solid #BCE048;
        border-radius: 50%;
        width: 27px;
        height: 25px;
        margin: -10px 0 0 -100%;
        display: inline-block; }
        @media screen and (max-width: 39.9375em) {
          .node-firmengeschichte .owl-logo .line-green:before {
            visibility: hidden; } }
      .node-firmengeschichte .owl-logo .line-green:after {
        content: "";
        float: right;
        margin: -65px -75px 0 0;
        background-image: url("/sites/all/themes/gensel/img/artistic-brush.png");
        background-size: contain;
        width: 80px;
        height: 80px;
        background-repeat: no-repeat; }
        @media screen and (max-width: 64em) {
          .node-firmengeschichte .owl-logo .line-green:after {
            margin: -48px -50px 0 0;
            width: 60px;
            height: 60px; } }
        @media screen and (max-width: 39.9375em) {
          .node-firmengeschichte .owl-logo .line-green:after {
            visibility: hidden; } }
  .node-firmengeschichte .geschichte {
    width: 80%;
    margin: 0 auto; }
    @media screen and (max-width: 64em) {
      .node-firmengeschichte .geschichte {
        margin: -17px auto 0 auto; } }
    @media screen and (max-width: 39.9375em) {
      .node-firmengeschichte .geschichte {
        margin: -30px auto 0 auto; } }
    .node-firmengeschichte .geschichte .owl-item {
      margin-top: 15px; }
      .node-firmengeschichte .geschichte .owl-item .items {
        text-align: center;
        font-size: 25px;
        color: #4A494A;
        letter-spacing: 1.02px;
        font-weight: 700;
        margin: 10px 0 10px 0; }
        .node-firmengeschichte .geschichte .owl-item .items:before {
          content: "|";
          color: #BCE048;
          margin-left: 23px;
          top: -26px;
          position: absolute; }
          @media screen and (max-width: 64em) {
            .node-firmengeschichte .geschichte .owl-item .items:before {
              left: 149%; } }
          @media screen and (max-width: 39.9375em) {
            .node-firmengeschichte .geschichte .owl-item .items:before {
              left: 147%; } }
        .node-firmengeschichte .geschichte .owl-item .items p {
          margin: 0;
          font-weight: 400;
          text-align: center;
          font-size: 12px;
          color: #4A494A;
          letter-spacing: 0.37px; }
          @media screen and (max-width: 39.9375em) {
            .node-firmengeschichte .geschichte .owl-item .items p {
              width: 165px !important;
              margin: 0 auto; } }
    .node-firmengeschichte .geschichte .owl-nav {
      position: absolute;
      top: -20px;
      left: -15%;
      width: 130%;
      color: transparent; }
      @media screen and (max-width: 64em) {
        .node-firmengeschichte .geschichte .owl-nav {
          top: -25px; } }
      @media screen and (max-width: 39.9375em) {
        .node-firmengeschichte .geschichte .owl-nav {
          width: 118%;
          left: -20px; } }
      .node-firmengeschichte .geschichte .owl-nav .owl-prev {
        display: inline-block;
        left: 20px;
        border-radius: 50%;
        margin: -8px 0 0 0;
        padding: 1px 0 0 14px;
        font-size: 30px;
        color: #4A494A;
        opacity: 0.5; }
        @media screen and (max-width: 64em) {
          .node-firmengeschichte .geschichte .owl-nav .owl-prev {
            margin: -3px 0 0 -10px; } }
  @media screen and (max-width: 64em) and (orientation: landscape) {
    .node-firmengeschichte .geschichte .owl-nav .owl-prev {
      margin: -7px 0 0 -30px; } }
      .node-firmengeschichte .geschichte .owl-nav .owl-next {
        display: inline-block;
        float: right;
        margin: -8px 0 0 0;
        padding: 1px 0 0 14px;
        right: 20px;
        width: 30px;
        border-radius: 50%;
        height: 50px;
        font-size: 30px;
        color: grey;
        opacity: 0.5; }
        @media screen and (max-width: 64em) {
          .node-firmengeschichte .geschichte .owl-nav .owl-next {
            padding: 5px 0 0 10px; } }
  @media screen and (max-width: 64em) and (orientation: landscape) {
    .node-firmengeschichte .geschichte .owl-nav .owl-next {
      padding: 3px 0 0 30px; } }

.node-webform {
  background-color: #d8d0cd;
  padding: 0;
  margin: 0; }
  .node-webform h2 {
    display: none; }
  .node-webform .content {
    margin-top: 3em; }
    @media screen and (max-width: 89.9375em) {
      .node-webform .content {
        margin: 0 auto; } }
    @media screen and (max-width: 786px) {
      .node-webform .content {
        margin-top: -25px; } }
    @media screen and (max-width: 421px) {
      .node-webform .content {
        margin-top: -50px; } }
    .node-webform .content .field-name-field-text {
      position: relative;
      width: 500px;
      color: #FAFAFA;
      font-weight: 600;
      top: 300px;
      float: right;
      line-height: 1.5; }
      @media screen and (max-width: 89.9375em) {
        .node-webform .content .field-name-field-text {
          float: none;
          width: 90%;
          margin: 0 auto;
          top: 450px;
          left: 5px; } }
      @media screen and (max-width: 786px) {
        .node-webform .content .field-name-field-text {
          top: 475px; } }
      @media screen and (max-width: 421px) {
        .node-webform .content .field-name-field-text {
          top: 500px; } }
    .node-webform .content .webform-client-form {
      margin: 0 auto;
      width: 80%; }
      @media screen and (max-width: 89.9375em) {
        .node-webform .content .webform-client-form {
          width: 90%; } }
      @media screen and (max-width: 39.9375em) {
        .node-webform .content .webform-client-form {
          margin-top: 55px; } }
      .node-webform .content .webform-client-form .form-item {
        margin: 20px 0;
        width: 42%; }
        @media screen and (max-width: 89.9375em) {
          .node-webform .content .webform-client-form .form-item {
            width: 100%; } }
        .node-webform .content .webform-client-form .form-item input {
          height: 50px; }
        .node-webform .content .webform-client-form .form-item.webform-component--nachricht {
          position: relative;
          margin: 0;
          width: 50%;
          top: -210px;
          float: right; }
          .node-webform .content .webform-client-form .form-item.webform-component--nachricht:after {
            content: 'Vielen Dank für Ihre Nachricht an uns. Wir bearbeiten Ihre Anfrage so schnell wie möglich.';
            color: #FAFAFA;
            font-weight: 600;
            position: absolute;
            bottom: -65px;
            left: 0; }
          @media screen and (max-width: 89.9375em) {
            .node-webform .content .webform-client-form .form-item.webform-component--nachricht {
              top: auto;
              float: none;
              width: 100%;
              margin: 20px 0; }
              .node-webform .content .webform-client-form .form-item.webform-component--nachricht:after {
                content: ''; } }
          .node-webform .content .webform-client-form .form-item.webform-component--nachricht textarea {
            height: 190px; }
            @media screen and (max-width: 89.9375em) {
              .node-webform .content .webform-client-form .form-item.webform-component--nachricht textarea {
                height: 100px;
                width: 100%; } }
        .node-webform .content .webform-client-form .form-item label {
          display: none; }
        @media screen and (max-width: 89.9375em) {
          .node-webform .content .webform-client-form .form-item input {
            width: 100%; } }
        .node-webform .content .webform-client-form .form-item .description {
          display: none; }
      .node-webform .content .webform-client-form .form-actions {
        margin-bottom: 30px;
        position: relative; }
        @media screen and (max-width: 89.9375em) {
          .node-webform .content .webform-client-form .form-actions {
            margin-top: 20px; }
            .node-webform .content .webform-client-form .form-actions:after {
              content: 'Vielen Dank für Ihre Nachricht an uns. Wir bearbeiten Ihre Anfrage so schnell wie möglich.';
              color: #FAFAFA;
              font-weight: 600; } }
        .node-webform .content .webform-client-form .form-actions input {
          background: #524E48;
          border-radius: 4px;
          height: 55px;
          color: #FAFAFA;
          width: 42%;
          font-family: LucidaGrande-Bold;
          font-size: 20px; }
          @media screen and (max-width: 89.9375em) {
            .node-webform .content .webform-client-form .form-actions input {
              width: 100%;
              margin-bottom: 15px; } }

#node-13 .content {
  position: relative;
  text-align: center; }
  #node-13 .content .paragraphs-item-bild {
    position: relative;
    margin: 50px 25px; }
    #node-13 .content .paragraphs-item-bild img {
      padding-left: 45px; }
  #node-13 .content .paragraphs-item-impressum-text {
    padding: 10px 15px 0 15px;
    width: 100%;
    max-width: 1000px;
    margin: 0 auto; }
    #node-13 .content .paragraphs-item-impressum-text p {
      color: #4A4A4A;
      font-size: 18px;
      margin: 0 0 15px 0; }
      @media screen and (max-width: 39.9375em) {
        #node-13 .content .paragraphs-item-impressum-text p {
          font-size: 16px; } }
      #node-13 .content .paragraphs-item-impressum-text p a {
        margin: 0;
        color: black; }
        #node-13 .content .paragraphs-item-impressum-text p a:hover {
          color: #4A494A; }
      #node-13 .content .paragraphs-item-impressum-text p:last-child {
        margin-bottom: 80px; }
    #node-13 .content .paragraphs-item-impressum-text strong {
      font-size: 23px; }

/*style the box*/
.gm-style .gm-style-iw {
  text-align: center;
  background-color: #4A494A;
  padding: 15px;
  border-radius: 10px;
  min-width: 260px !important;
  top: 15px !important;
  left: -10px !important;
  visibility: visible !important; }
  .gm-style .gm-style-iw #infobox {
    background-color: #4A494A;
    overflow: auto; }
    .gm-style .gm-style-iw #infobox p {
      color: white;
      font-size: 15px;
      letter-spacing: 0.57px; }
    .gm-style .gm-style-iw #infobox a {
      color: #BCE048;
      font-weight: 400;
      transition: all 0.5s; }
      .gm-style .gm-style-iw #infobox a:hover {
        color: #FAFAFA; }
    .gm-style .gm-style-iw #infobox .logo-map {
      margin: 0 15px;
      width: 20px; }

.gm-style > div > div:nth-of-type(4) > div:nth-of-type(4) > div {
  visibility: hidden; }

/*style the arrow*/
.gm-style > div > div:nth-of-type(4) > div:nth-of-type(4) > div > div > div:nth-of-type(3) {
  visibility: visible;
  left: 106px !important; }
  .gm-style > div > div:nth-of-type(4) > div:nth-of-type(4) > div > div > div:nth-of-type(3) div > div {
    background-color: #4A494A !important;
    box-shadow: none !important; }

.footer {
  background-color: #4A494A;
  width: 100%;
  margin: 0;
  padding: 40px 0; }
  .footer .content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; }
    .footer .content #map {
      flex: 1 0 100%;
      padding: 0;
      height: 500px;
      width: 100%; }
    .footer .content .field-name-field-links {
      margin: 0 auto;
      display: inline-block;
      vertical-align: middle; }
      .footer .content .field-name-field-links p {
        width: 100px;
        display: inline-block; }
        .footer .content .field-name-field-links p a {
          color: #9B9A9B;
          font-weight: 400;
          font-size: 15px; }
          .footer .content .field-name-field-links p a:hover {
            color: #FAFAFA !important; }
      @media screen and (max-width: 39.9375em) {
        .footer .content .field-name-field-links {
          width: 100%;
          text-align: center; }
          .footer .content .field-name-field-links p {
            width: 120px; } }
    .footer .content .field-name-field-logo {
      margin: 0 auto;
      display: inline-block;
      background-size: cover;
      padding-top: 40px; }
      @media screen and (max-width: 64em) {
        .footer .content .field-name-field-logo {
          margin: 0 auto;
          display: inline-block; } }
      @media screen and (max-width: 455px) {
        .footer .content .field-name-field-logo {
          width: 100%;
          text-align: center;
          display: block; } }
      .footer .content .field-name-field-logo:before {
        content: "Gensel";
        display: inline-block;
        color: #9B9A9B;
        margin-right: 20px; }
        @media screen and (max-width: 64em) {
          .footer .content .field-name-field-logo:before {
            display: inline-block; }
            .footer .content .field-name-field-logo:before .field-item {
              margin: 0 auto; } }
        @media screen and (max-width: 39.9375em) {
          .footer .content .field-name-field-logo:before {
            margin-right: 30px; } }
      .footer .content .field-name-field-logo:after {
        content: "GmbH";
        display: inline-block;
        color: #9B9A9B;
        margin-left: 20px; }
        @media screen and (max-width: 64em) {
          .footer .content .field-name-field-logo:after {
            display: inline-block; }
            .footer .content .field-name-field-logo:after .field-item {
              margin: 0 auto; } }
      .footer .content .field-name-field-logo .field-items {
        display: inline-block; }
        @media screen and (max-width: 39.9375em) {
          .footer .content .field-name-field-logo .field-items {
            margin: 0 -5px 0 0; } }
        .footer .content .field-name-field-logo .field-items .field-item {
          margin: 0 auto; }
    .footer .content .field-name-field-website-betreeung {
      margin: 0 auto;
      display: inline-block;
      vertical-align: middle;
      padding-top: 40px;
      color: #9B9A9B;
      font-size: 11px; }
      .footer .content .field-name-field-website-betreeung a {
        color: #9B9A9B;
        font-weight: 400; }
        .footer .content .field-name-field-website-betreeung a:hover {
          color: white !important; }
      @media screen and (min-width: 40em) and (max-width: 64em) {
        .footer .content .field-name-field-website-betreeung {
          padding: 40px 10px 20px 10px; } }
      @media screen and (max-width: 39.9375em) {
        .footer .content .field-name-field-website-betreeung {
          padding: 30px 10px 20px 10px;
          width: 100%;
          text-align: center; } }

@media screen and (min-width: 641px) {
  h2 {
    font-size: 2rem; }
    h2.section-heading {
      font-size: 2.5rem; }
  .titel-thumbnail-referenzen {
    display: block;
    margin: 0 0 0 15px; }
  .thumbnail_referenz {
    width: 100px;
    height: 100px;
    margin: 20px; } }

@media screen and (min-width: 741px) {
  .slider-projekt .item-projekt .item-images {
    height: 500px; }
  .slider-projekt .item-projekt .owl-text .text-wrapper {
    background: rgba(74, 74, 74, 0.7);
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    z-index: 10;
    color: #FAFAFA;
    padding: 20px 15px; }
    .slider-projekt .item-projekt .owl-text .text-wrapper .text {
      padding-bottom: 0; }
    .slider-projekt .item-projekt .owl-text .text-wrapper .button-wrapper {
      position: absolute;
      bottom: 20px;
      left: 0; }
  .slider-projekt .owl-nav {
    position: absolute;
    width: calc(100% - 275px);
    top: 50%;
    left: calc(0% + 275px);
    color: #FAFAFA; }
  .slider-projekt .owl-dots {
    width: calc(100% - 275px);
    top: 93%;
    left: calc(0% + 275px); } }

@media screen and (min-width: 790px) {
  .view-taxonomie-ansicht-leistungen .taxonomy-term {
    width: 194px;
    height: 174px;
    padding: 30px 30px; }
  #node-13 .content {
    text-align: left; }
    #node-13 .content .paragraphs-item-bild {
      position: absolute;
      padding-left: 0;
      margin: 80px 0 0;
      right: 15px;
      top: 50px; }
      #node-13 .content .paragraphs-item-bild img {
        padding-left: 0;
        max-width: 95%; } }

@media screen and (min-width: 841px) {
  #node-13 .content .paragraphs-item-bild {
    right: 15%; } }
